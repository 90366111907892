.BlockDropdown {
  position: relative;
  display: inline-block;
  width: 200px;
  border: 1px solid #e4e4e4;
  padding: 0.4rem 0.875rem;
  border-radius: 0.3rem;
  margin-right: 0.5rem;

  .BlockDropdown-cont {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;

    img {
      height: 0.85rem;
    }
    .dropdown-button {
      font-family: "Inter";
      font-size: 0.85rem;
      font-weight: 500;
      color: #19191c;
    }
  }

  .dropdown-content {
    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 2px 6px 0px #0000001f;
    z-index: 1;
    margin-left: -0.75rem;
    border-radius: 0.5rem;
    margin-top: 0.7rem;
    .location {
      font-family: "Inter";
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: #19191c;
      display: block;
      padding: 0.6rem 0.875rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      img {
        height: 0.85rem;
      }
    }
    .disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    .location:hover {
      background-color: #eee9fd;
    }
  }
}
