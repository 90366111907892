@import "../../colors/colors.scss";
.input-component {
  display: block;

  .label {
    margin-bottom: 0.5rem;
    color: #444444;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.2rem;
    margin-left: 0.5rem;
  }
  input {
    width: 100%;
    padding: 0.6rem 1rem;
    font-size: 0.938rem;
    border-radius: 0.25rem;
    border: 1px solid $border-grey;
    outline: none;
    font-family: "Inter";
    font-weight: 400;
    line-height: 1.2rem;
    color: #202020;
  }
  .diabled {
    background-color: #e8e8e8;
  }
}
.payment-input-component {
  display: block;
  .label {
    margin-bottom: 0.5rem;
    color: #444444;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.2rem;
    margin-left: 0.5rem;
  }
  .input-cont {
    border-radius: 0.25rem;
    border: 1px solid $border-grey;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      font-size: 0.938rem;
      height: 100%;
      outline: none;
      font-family: "Inter";
      font-weight: 400;
      color: #202020;
      padding: 0.7rem 1rem;
      border: none;
      outline: none;
      border-radius: 0.25rem;
      color: #202020;
      line-height: 1.2rem;
    }
    .amount {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 400;
      color: #202020;
    }
  }

  .diabled {
    background-color: #e8e8e8;
  }
}
.number-input-component {
  display: block;

  .label {
    margin-bottom: 0.5rem;
    color: #444444;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.2rem;
    margin-left: 0.5rem;
  }
  .num-inpt {
    width: 100%;
    padding: 0.6rem 1rem;
    font-size: 0.938rem;
    border-radius: 0.25rem;

    input {
      width: 100%;
      padding: 0.6rem 1rem;
      font-size: 0.938rem;
      border-radius: 0.25rem;
      outline: none;
      font-family: "Inter";
      font-weight: 400;
      line-height: 1.2rem;
      color: #202020;
    }
  }

  .diabled {
    background-color: #e8e8e8;
  }
}
.Auth {
  .error {
    color: red;
    font-family: "Inter";
    font-size: 0.7rem;
    font-weight: 500;
  }
  margin-bottom: 1.5rem;
  .auth-input-component {
    display: flex;

    border: 1px solid #e8e8e8;
    border-radius: 0.25rem;
    align-items: center;

    input {
      width: 80%;
      outline: none;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.2rem;
      color: #202020;
      height: 3rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: none;
      outline: none;
    }
    input::placeholder {
      color: #84818a;
      font-size: 0.875rem;
    }
    img {
      height: 1.2rem;
      width: 1.2rem;
      margin: auto;
      cursor: pointer;
    }
    .diabled {
      background-color: #e8e8e8;
    }
  }
}

.css-1eed5fa-MuiInputBase-root-MuiInput-root {
  width: 100%;
  padding: 0.2rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid $border-grey;
  background-color: #fff !important;
  height: 40px;
}
.css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
  border-bottom: none !important;
  left: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  right: 0;
  -webkit-transition: none !important;
  transition: none !important;
  pointer-events: none;
}
.css-1eed5fa-MuiInputBase-root-MuiInput-root::after {
  border-bottom: none !important;
  left: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  right: 0;
  -webkit-transition: none !important;
  transition: none !important;
  pointer-events: none;
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    width: 95%;
  }
}
