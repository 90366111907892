$circle-size: 40px;

.step-container {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  width: 50%;
  .step {
    align-items: center;
    display: flex;
    flex-direction: column;
    .step-circle {
      width: $circle-size;
      height: $circle-size;
      border-radius: 50%;
      background-color: #9696be42;
      color: #9696be;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 1rem;
      font-family: "Inter";
      &.active {
        background-color: #9696be;
        color: #fff;
      }
    }

    .step-name {
      margin-top: 0.625rem;
      font-family: "Inter";
      font-size: 0.875rem;
      color: #2d2d55;

      &.active {
        font-weight: bold;
        color: #2d2d55;
      }
    }
  }
  .line {
    width: 80%;
    height: 1px;
    background-color: #9696be42;
    margin-top: 20px;
    &.active {
      background-color: #9696be;
    }
  }
}
@media only screen and (max-width: 600px) {
  .step-container {
    width: 90%;
    margin: auto;
    margin-top: 1.5rem;
  }
}
