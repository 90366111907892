.restrict-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 1010;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.restrict-modal-content {
  background: white;
  border-radius: 0.25rem;
  width: 440px;
  animation: zoomIn 0.3s ease-in-out;
  margin: auto;

  .alert-danger {
    width: 95%;
    margin: auto;
    border: 1px solid #ebadad;
    background-color: #faebeb;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    .left-content {
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem !important;
        width: 1rem !important;
        background-color: #cc3333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        img {
          height: 0.5rem;
          width: 0.5rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
  .container {
    .error {
      color: red;
      font-family: "Inter";
      font-size: 0.7rem;
    }
    .header {
      font-family: Inter;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;
      border-bottom: 1px solid #ebeaed;

      img {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
      }
    }
    .content {
      width: calc(94% - 2rem);
      padding: 2rem;
      .date-container {
        display: flex;
        justify-content: space-between;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        column-gap: 1.25rem;
        .start {
          width: 50%;
        }
        .end {
          width: 50%;
        }
      }
      .time-container {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        column-gap: 1.25rem;
        .start {
          width: 50%;
          select {
            margin-top: 0.5rem;
            width: 11.25rem;
            padding: 0.6rem 1rem;
            font-size: 1rem;
            border-radius: 0.25rem;
            border: 1px solid #e8e8e8;
            outline: none;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #202020;
            -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
            -moz-appearance: none; /* Remove default arrow in Firefox */
            appearance: none; /* Remove default arrow in other browsers */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="gray"/></svg>');
            background-repeat: no-repeat;
            background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
            background-size: 10px auto, 100%;
          }
        }
      }
      .block-tables-container {
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        margin-bottom: 30px;
        .header-tables-list {
          margin-bottom: 20px;
        }
        .tables-list {
          margin-bottom: 20px;
        }
      }
      .block-type-container {
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        .block-type-list-container {
          width: 100%;
          height: 2.5rem;
          border: 1px solid #00000024;
          border-radius: 0.25rem;
          margin-top: 0.625rem;
          display: flex;
          align-items: center;
          .block-type {
            width: 33%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            cursor: pointer;
            border-right: 1px solid #00000024;
          }
          .block-type-end {
            width: 34%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            cursor: pointer;
          }
          .selected {
            background-color: #eee9fd;
          }
        }
      }
    }
  }
}
@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
