.restrict-tables-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 1010;
  overflow-y: auto;
  padding: 4%;
}
.restrict-tables-modal-content {
  background: white;
  border-radius: 0.25rem;
  width: 440px;
  animation: zoomIn 0.3s ease-in-out;
  margin: auto;
  .container {
    .header {
      font-family: Inter;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;
      border-bottom: 1px solid #ebeaed;

      img {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
      }
    }
    .content {
      width: calc(94% - 2rem);
      padding: 2rem;
      .floor-type {
        min-height: 1.2rem;
        width: 100%;
        border: 1px solid #e8e8e8;
        cursor: pointer;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
        .floor-type-details {
          display: flex;
          align-items: center;
          height: 1.2rem;
          padding: 0.938rem 1.5rem;
          .left-shift-view {
            font-family: Inter;
            font-size: 0.875rem;
            font-weight: 500;
            text-align: left;
            width: 100%;
          }
        }
      }
      .floor-type-selectd {
        .floor-type-details {
          border-bottom: 1px solid #e8e8e8;
          .right-shift-view {
            .arrow-cont {
              img {
                transform: rotate(-180deg);
              }
            }
          }
        }
      }
      .tables {
        width: calc(100% - 3rem);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.938rem 1.5rem;
        .table {
          font-family: Inter;
          font-size: 0.875rem;
          font-weight: 400;
          cursor: pointer;
          padding: 0.8rem;
          margin-bottom: 0.4rem;
          border-radius: 0.25rem;
          color: #202020;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .cover-text {
            color: #5c5f62;
          }
        }
        .table-selected {
          background-color: #eee9fd;
        }
      }
    }
  }
}
