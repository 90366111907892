@import "../../colors/colors.scss";
.LogoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    text-align: center;
    .logo-cont {
      width: 100%;
      display: flex;
      justify-content: center;
      .logo {
        height: 18rem;
        display: flex;
      }
    }

    .location {
      font-family: "Inter";
      font-size: 1rem;
      font-weight: 500;
      line-height: 24px;
      background-color: $secondary-brown;
      width: max-content;
      margin: auto;
      padding: 0.1rem 2rem;
      text-transform: uppercase;
      border-radius: 0.25rem;
      color: $white;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      img {
        height: 0.9rem;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
          saturate(7500%) hue-rotate(243deg) brightness(109%) contrast(103%);
      }
    }
    .det {
      .time-contaner {
        margin-top: 1.5rem;
        border: 2px solid $secondary-brown;
        padding: 1rem 2.5rem;
        .head {
          font-family: "Inter";
          font-size: 1.1rem;
          font-weight: 300;
          line-height: 24px;
          text-align: center;
          color: $white;
          margin-bottom: 1rem;
        }
        .times {
          font-family: "Inter";
          font-size: 1.1rem;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: $secondary-brown;
          text-transform: capitalize;
          display: flex;
          justify-content: space-between;
          column-gap: 1rem;
          margin-top: 0.3rem;
        }
        .bold {
          font-weight: 600;
          color: #e4e4e4;
        }
      }
      .details {
        margin-top: 1rem;
        .bookings {
          color: $secondary-brown;
          text-transform: uppercase;
          font-family: "Inter";
          font-size: 0.75rem;
          font-weight: 600;
          line-height: 1.2rem;
          text-align: center;
        }
        .sub {
          color: $medium-grey;
          font-family: "Inter";
          font-size: 0.75rem;
          font-weight: 600;
          line-height: 1.2rem;
          text-align: center;
        }
      }
      .address {
        margin-top: 1rem;
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.2rem;
        text-align: center;
        color: $drak-grey;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .LogoContainer {
    .container {
      display: flex;
      width: 100%;
      column-gap: 2%;
      .logo-cont {
        width: 45%;
        .logo {
          height: 7rem;
        }
      }
      .location {
        display: none;
      }
      .det {
        .time-contaner {
          display: none;
        }
        .details {
          margin-top: 2.5rem;
        }
        .address {
          display: none;
        }
        .logo-witmeg {
          display: none;
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .LogoContainer {
    .container {
      display: flex;
      width: 100%;
      padding-top: 5%;
      padding-bottom: 5%;
      column-gap: 2%;
      .logo-cont {
        width: 50%;
      }
    }
    .location {
      display: none;
    }
    .det {
      .logo-witmeg {
        display: none;
      }
    }
  }
}
