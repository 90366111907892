.CustomBanner {
  border: 1px solid rgb(194, 194, 194);
  width: max-content;
  margin: auto;
  margin-top: 1rem;
  border-radius: 0.25rem;
  .mainText {
    font-size: 0.875rem;
    color: #cc1111;
    font-family: "Inter";
    font-weight: 500;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 0.5rem;

    span {
      font-size: 1.3rem;
    }
  }
  .belowText {
    font-size: 0.75rem;
    color: #5c5c5c;
    font-family: "Inter";
    font-weight: 400;
    margin-top: 0.1rem;
    margin-bottom: 0.75rem;
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .CustomBanner {
    .belowText {
      margin-top: 0.5rem;
    }
  }
}
