@import "../../../colors/colors.scss";

.Reservation {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  .landscape {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .empty {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    .loader {
      border: 0.1rem solid #9d9d9d;
      border-top: 0.15rem solid transparent;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      animation: spin 1s linear infinite;
      margin: auto;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .MuiPhoneNumber-flagButton {
    height: 16px;
    padding: 0;
    min-width: 16px;
  }
  .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    font-family: "Inter";
    font-weight: 400;
    font-size: 0.75rem;
    color: #202020;
  }
  .reservation-content {
    width: 100%;
    background-color: #f9f9f9;
    position: relative;
    overflow: hidden;
    .loader {
      border: 0.1rem solid #9d9d9d;
      border-top: 0.15rem solid transparent;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      animation: spin 1s linear infinite;
      margin: auto;
      margin-top: 10%;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .list-view {
      ::-webkit-scrollbar {
        width: 5px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #dfdfdf;
      }
      margin-top: 4.05rem;
      .table-view {
        padding: 3%;
        overflow-y: auto;
        padding-bottom: 8%;
        padding-top: 5%;
        .empty-container {
          width: 100%;
          text-align: center;
          padding-top: 2%;
          padding-bottom: 2%;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #84818a;
          background-color: #f9f9f9;
          border-bottom-left-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
          span {
            color: #19191c;
            margin-left: 0.5rem;
            font-weight: 500;
          }
        }
      }
    }
    .floor-view {
      overflow: hidden;
      height: 91vh;
      width: 100%;
      display: flex;
      position: relative;
      margin-top: 4.05rem;
      ::-webkit-scrollbar {
        width: 5px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #dfdfdf;
      }
      .left-view {
        width: 28%;
        height: 100%;
        background-color: transparent;
        border-right: 1px solid #ededed;
        position: relative;
        overflow: auto;
        background-color: #fff;
        .fixed-view {
          position: fixed;
          width: 26%;
          z-index: 10;
          .header-container {
            display: flex;
            padding-top: 1.375rem;
            width: 100%;
            border-bottom: 1px solid #ededed;
            background-color: #fff;

            .content {
              width: inherit;
              text-align: center;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 600;
              color: #84818a;
              padding-bottom: 0.75rem;
            }
            .content-selected {
              color: #6f47eb;
              border-bottom: 2px #6f47eb solid;
            }
          }
          .search-container {
            padding: 1.25rem 2.5rem;
            border-bottom: 1px solid #ededed;
            background-color: #fff;
          }
        }

        .table-view {
          padding: 3%;
          padding-bottom: 20%;
          padding-top: 12rem;
          .empty-container {
            width: 100%;
            text-align: center;
            padding-top: 2%;
            padding-bottom: 2%;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #84818a;
            background-color: #f9f9f9;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            span {
              color: #19191c;
              margin-left: 0.5rem;
              font-weight: 500;
            }
          }
        }
      }
      .right-view {
        width: 73%;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .reservation-view {
      margin-top: 4.05rem;
      display: flex;
      position: relative;
      overflow: hidden;
      height: 91vh;
      ::-webkit-scrollbar {
        width: 5px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #dfdfdf;
      }
      .left-view {
        width: 28%;
        border-right: 1px solid #ededed;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #fff;
        .search-bar {
          background-color: #fff;
          padding: 1.25rem;
          border-bottom: #ebeaed 1px solid;
          input {
            width: 93%;
          }
        }
        .guest-info {
          background-color: #fff;
          padding: 1.25rem 1.5rem;
          .header {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 500;
            color: #202020;
            display: flex;
            column-gap: 0.5rem;
            align-items: center;
            margin-bottom: 1.25rem;
            img {
              height: 0.875rem;
              width: 0.87rem;
            }
          }
          .name-container {
            display: flex;
            width: 100%;
            column-gap: 2.5rem;
            margin-top: 0.5rem;
            .error {
              color: red;
              font-family: "Inter";
              font-size: 0.7rem;
            }
            .left {
              width: 40%;
            }
            .right {
              width: 45%;
            }
          }
        }
        .inner-container {
          background-color: #fff;
          height: auto;
          .item-cont {
            .alert-danger {
              width: 78%;
              margin: auto;
              border: 1px solid #ebadad;
              background-color: #faebeb;
              padding: 0.75rem;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              margin-top: 1rem;

              .left-content {
                width: 10%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                .cancel {
                  height: 1rem !important;
                  width: 1rem !important;
                  background-color: #cc3333;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  justify-content: center;
                  img {
                    height: 0.5rem;
                    width: 0.5rem;
                  }
                }
              }
              .right-content {
                width: 90%;
                display: flex;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                color: #1a1a1a;
              }
            }
            .alert-warning {
              width: 78%;
              margin: auto;
              border: 1px solid #f7e1a1;
              background-color: #fdf8e8;
              padding: 0.75rem;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              margin-top: 1rem;

              .left-content {
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                color: #1a1a1a;
                width: 10%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                .cancel {
                  height: 1rem;
                  width: 1rem;
                  background-color: #deaf21;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  justify-content: center;
                  .danger {
                    height: 0.5rem;
                    width: 0.5rem;
                  }
                  .warning {
                    height: 0.85rem;
                    width: 0.85rem;
                  }
                }
              }
              .right-content {
                width: 90%;
                display: flex;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                color: #1a1a1a;
              }
            }
            .alert-warning-2 {
              width: 78%;
              margin: auto;
              border: 1px solid #f7e1a1;
              background-color: #fdf8e8;
              padding: 0.75rem;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              margin-top: 1rem;

              .left-content {
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                color: #1a1a1a;
                width: 10%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                .cancel {
                  height: 1rem;
                  width: 1rem;
                  background-color: #deaf21;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  justify-content: center;
                  .danger {
                    height: 0.5rem;
                    width: 0.5rem;
                  }
                  .warning {
                    height: 0.85rem;
                    width: 0.85rem;
                  }
                }
              }
              .right-content {
                width: 90%;
                display: flex;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                color: #1a1a1a;
              }
            }
            .item {
              border: #e8e8e8 1px solid;

              margin: 0.5rem 1rem;
              padding: 1rem 1.125rem;
              font-family: "Inter";
              font-size: 0.8rem;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              position: relative;
              border-radius: 0.5rem;
              .det {
                display: flex;
                align-items: center;
                column-gap: 2.5rem;
                width: 100%;
                .head {
                  display: flex;
                  align-items: center;
                  column-gap: 0.75rem;
                  font-family: "Inter";
                  font-size: 0.875rem;
                  font-weight: 500;
                  color: #202020;
                  width: 150px;
                  img {
                    height: 0.75rem;
                    width: 0.75rem;
                  }
                }
                .gradient-text {
                  color: #6f47eb;

                  cursor: pointer;
                }
                .sub {
                  font-family: "Inter";
                  font-size: 0.8rem;
                  font-weight: 500;
                  text-align: left;
                  font-family: "Inter";
                  font-size: 0.875rem;
                  width: 70%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .arrow-cont {
                img {
                  height: 0.75rem;
                  width: 0.75rem;
                  transform: rotate(90deg);
                }
              }
              .arrow-cont-sel {
                display: flex;
                align-items: center;
                img {
                  height: 0.875rem;
                  width: 0.875rem;
                  transform: rotate(-90deg);
                }
              }
              .status-list {
                position: absolute;
                width: 14.438rem;
                background-color: #fff;
                right: -14.438rem;
              }
            }
            .item-def {
              border: #e8e8e8 1px solid;

              margin: 0.5rem 1rem;
              // padding: 1rem 1.125rem;
              padding-left: 1.125rem;
              font-family: "Inter";
              font-size: 0.8rem;
              font-weight: 500;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              position: relative;
              border-radius: 0.5rem;
              .det {
                display: flex;
                align-items: center;
                column-gap: 2.5rem;
                width: 100%;
                .head {
                  display: flex;
                  align-items: center;
                  column-gap: 0.75rem;
                  font-family: "Inter";
                  font-size: 0.875rem;
                  font-weight: 500;
                  color: #202020;
                  width: 150px;
                  img {
                    height: 0.75rem;
                    width: 0.75rem;
                  }
                }
                .gradient-text {
                  color: #6f47eb;

                  cursor: pointer;
                }
                .sub {
                  font-family: "Inter";
                  font-size: 0.8rem;
                  font-weight: 500;
                  text-align: left;
                  font-family: "Inter";
                  font-size: 0.875rem;
                  width: 70%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .arrow-cont {
                img {
                  height: 0.75rem;
                  width: 0.75rem;
                  transform: rotate(90deg);
                }
              }
              .arrow-cont-sel {
                display: flex;
                align-items: center;
                img {
                  height: 0.875rem;
                  width: 0.875rem;
                  transform: rotate(-90deg);
                }
              }
              .status-list {
                position: absolute;
                width: 14.438rem;
                background-color: #fff;
                right: -14.438rem;
              }
            }
            .item-sel {
              background: #f5f1ff;
            }
            .input-cont {
              width: 85%;
              margin-left: 3%;
              margin-bottom: 2rem;
            }
            .date-cont {
              height: 18rem;
              width: 85%;
              left: 36%;
              margin-top: -6%;
              position: absolute;
              .iner {
                transform: none !important;
              }
            }
          }
        }
        .status-container {
          padding: 1.25rem 1.5rem;
          border-top: #e8e8e8 solid 1px;
          border-bottom: #e8e8e8 solid 1px;
          display: flex;
          justify-content: space-between;
          background-color: #f5f5f5;
          .item {
            background-color: #fff;
            width: 42%;
            border: 1.2px solid #00000024;
            padding: 10px;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            font-family: "Inter";
            font-size: 0.75rem;
            font-weight: 500;
            .check {
              height: 1rem;
              width: 1rem;
              border: 1px solid #cccccc;
              border-radius: 3px;
              margin-right: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              .checked {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 1rem;
                width: 1rem;
                background: #6f47eb;

                img {
                  height: 0.7rem;
                  width: 0.7rem;
                  filter: brightness(0) saturate(100%) invert(100%) sepia(8%)
                    saturate(29%) hue-rotate(228deg) brightness(107%)
                    contrast(99%);
                  margin: 0;
                }
              }
              img {
                height: 1rem;
                width: 1rem;
              }
            }
            img {
              height: 0.9rem;
              width: 0.9rem;
              margin-right: 0.5rem;
            }
          }
        }

        .btn-container {
          padding: 1.25rem 1.5rem;
          border-top: #e8e8e8 solid 1px;
        }
      }
      .right-view {
        width: 73%;
        overflow: auto;
      }
    }
    .grouping-view {
      margin-top: 4.05rem;
      ::-webkit-scrollbar {
        width: 5px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #dfdfdf;
      }
      .grouping-fixed-view {
        height: 4rem;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #ebeaed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        column-gap: 1rem;
        .right-conts {
          font-family: Inter;
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
          margin-left: 3rem;
          display: flex;
          align-items: center;
          column-gap: 2rem;
          .switch-containers {
            display: flex;
            align-items: center;
            column-gap: 2rem;
            .switch {
              position: relative;
              display: inline-block;
              width: 44px;
              height: 24px;
            }

            .switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              transition: 0.4s;
              border-radius: 34px;
            }

            .slider:before {
              position: absolute;
              content: "";
              height: 20px;
              width: 20px;
              left: 2px;
              bottom: 2.1px;
              background-color: white;
              transition: 0.4s;
              border-radius: 50%;
            }

            input:checked + .slider {
              background-color: #6f47eb;
            }

            input:checked + .slider:before {
              transform: translateX(20px);
            }

            /* Rounded sliders */
            .slider.round {
              border-radius: 34px;
            }

            .slider.round:before {
              border-radius: 50%;
            }
          }
        }
        .btn-container {
          margin-right: 8%;
        }
      }
      .table-view {
        padding: 3%;
        overflow-y: auto;
        padding-bottom: 8%;
        padding-top: 7%;
        .error {
          color: red;
          font-family: "Inter";
          font-size: 0.7rem;
        }
        .table-grouping {
          box-shadow: 0px 1px 3px 0px #00000014;
          width: 60%;
          background-color: #fff;
          border-radius: 0.5rem;

          justify-content: space-between;
          margin-bottom: 1.5rem;
          height: auto;
          padding-bottom: 1rem;
          .grouping-header {
            height: 1.5rem;
            display: flex;
            align-items: center;
            width: 96.5%;
            padding: 0.938rem 1.5rem;
            cursor: pointer;
            font-family: "Inter";
            font-size: 18px;
            font-weight: 600;
          }
          .grouping-table {
            margin-top: -1rem;
            margin: 0rem 1.5rem;
            .arrow {
              height: 0.75rem;
              width: 0.75rem;
              margin-top: 0.5rem;
              cursor: pointer;
              margin-left: 1rem;
            }
            .icons-container {
              display: flex;
              align-items: center;
              column-gap: 0.65rem;
              img {
                height: 0.875rem;
                width: 0.875rem;
              }
            }
            .tables {
              display: flex;
              column-gap: 1rem;
              flex-wrap: wrap;
              row-gap: 0.5rem;
              padding: 0.5rem;
            }
            .more-icon {
              position: relative;
              img {
                height: 1rem;
                width: 1rem;
              }
              .dropdown-content {
                width: 9rem;
                padding: 1rem 0.875rem;
                border-radius: 0.3rem;
                position: absolute;
                background-color: #f9f9f9;
                min-width: 160px;
                box-shadow: 0px 2px 6px 0px #0000001f;
                z-index: 1;
                margin-left: -0.75rem;
                border-radius: 0.25rem;
                left: 0;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                text-align: left;
                color: #19191c;
                cursor: pointer;
                align-items: center;
                column-gap: 1rem;
                .item {
                  line-height: 1.8rem;
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  img {
                    height: 0.9rem;
                    width: 0.9rem;
                  }
                }
                .item:hover {
                  color: #6f47eb;
                  img {
                    filter: brightness(0) saturate(100%) invert(26%) sepia(71%)
                      saturate(3300%) hue-rotate(246deg) brightness(96%)
                      contrast(91%);
                  }
                }
              }
            }
            #bookings {
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              text-align: left;
              color: #202020;
              width: 100%;
            }
            #bookings td,
            #bookings th {
              padding: 1.125rem;
              border: none;
            }
            #bookings tr:nth-child(even) {
              background-color: #f6f6f6;
            }
            #bookings th {
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              font-family: "Inter";
              font-size: 0.75rem;
              font-weight: 400;
              text-align: left;
              color: #84818a;
            }
            #bookings th:first-child {
              min-width: 6rem;

              max-width: 6rem;
            }
            #bookings td:first-child {
              min-width: 6rem;
              max-width: 6rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            #bookings th:nth-child(2) {
              min-width: 10rem;
              max-width: 10rem;
            }
            #bookings td:nth-child(2) {
              max-width: 10rem;
              min-width: 10rem;
            }
            #bookings th:nth-child(3) {
              min-width: 4rem;
              max-width: 4rem;
              text-align: center;
            }
            #bookings td:nth-child(3) {
              min-width: 4rem;
              max-width: 4rem;
              padding: 0.5rem;
              text-align: center;
            }
            #bookings th:nth-child(4) {
              min-width: 4rem;
              max-width: 4rem;
              text-align: center;
            }
            #bookings td:nth-child(4) {
              min-width: 4rem;
              max-width: 4rem;
              padding: 0.5rem;
              text-align: center;
            }
            #bookings th:nth-child(5) {
              width: 4rem;
              max-width: 4rem;
            }
            #bookings td:nth-child(5) {
              width: 4rem;
              max-width: 4rem;
              text-align: center;
            }
          }
          .empty-container {
            width: 100%;
            text-align: center;
            padding-top: 2%;
            padding-bottom: 2%;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #84818a;
            background-color: #f9f9f9;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            span {
              color: #19191c;
              margin-left: 0.5rem;
              font-weight: 500;
            }
          }
        }
        .create-table {
          box-shadow: 0px 1px 3px 0px #00000014;
          width: 50%;
          background-color: #fff;
          border-radius: 0.5rem;

          justify-content: space-between;
          margin-bottom: 1.5rem;
          height: auto;
          padding-bottom: 1rem;
          .empty-container {
            width: 100%;
            text-align: center;
            padding-top: 2%;
            padding-bottom: 2%;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #84818a;
            background-color: #f9f9f9;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            span {
              color: #19191c;
              margin-left: 0.5rem;
              font-weight: 500;
            }
          }
          .grouping-header {
            height: 1.5rem;
            display: flex;
            align-items: center;
            padding: 0.938rem 1.5rem;
            cursor: pointer;
            font-family: "Inter";
            font-size: 1rem;
            font-weight: 500;
            border-bottom: 1px solid #e8e8e8;
          }
          .bottom-cont {
            padding: 0.938rem 1.5rem;
            .sub-header {
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 600;
              margin-bottom: 1.5rem;
            }
            .floors {
              height: 30vh;
              overflow-y: auto;
            }
            .floor-type {
              height: 1.2rem;
              display: flex;
              align-items: center;
              width: 90%;
              border: 1px solid #e8e8e8;
              padding: 0.938rem 1.5rem;
              cursor: pointer;
              border-radius: 0.5rem;
              margin-bottom: 1rem;
              .left-shift-view {
                font-family: Inter;
                font-size: 0.875rem;
                font-weight: 500;
                text-align: left;
                width: 50%;
              }
              .right-shift-view {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .arrow-cont {
                  height: 1.5rem;
                  width: 1.5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 1rem;
                  img {
                    height: 0.75rem;
                    width: 0.75rem;
                  }
                }
              }
            }
            .floor-type-selectd {
              background: #00000024;
              .right-shift-view {
                .arrow-cont {
                  img {
                    transform: rotate(-180deg);
                  }
                }
              }
            }
            .tables {
              width: 88%;
              padding: 0rem 2rem;
              .table {
                font-family: Inter;
                font-size: 0.875rem;
                font-weight: 400;
                cursor: pointer;
                padding: 0.8rem 1rem;
                margin-bottom: 0.4rem;
                border-radius: 0.25rem;
                color: #202020;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .check {
                  height: 1.5rem;
                  width: 1.5rem;
                  border: 1px solid #0000001a;
                  border-radius: 50%;
                }
                .checked {
                  height: 1.5rem;
                  width: 1.5rem;
                  background-color: #1f7b24;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    height: 0.7rem;
                    width: 0.7rem;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(1%)
                      saturate(2381%) hue-rotate(150deg) brightness(103%)
                      contrast(100%);
                  }
                }
              }
              .table-selected {
                background-color: #e5f1e5;
              }
            }
            .btn-cont {
              display: flex;
              justify-content: flex-end;
              margin-top: 2rem;
            }
          }
        }
      }
    }
    .shift-view {
      margin-top: 4.05rem;
      ::-webkit-scrollbar {
        width: 5px;
        height: 4px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #dfdfdf;
      }

      .shift-fixed-view {
        height: 4rem;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #ebeaed;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        column-gap: 1rem;
        .btn-container {
          margin-right: 8%;
          display: flex;
          align-items: center;
          column-gap: 0.75rem;

          .search-container {
            width: 11.5rem;
            background-color: #fff;
            padding: 0.5rem 0.75rem;
            border: 1.2px solid #00000024;
            border-radius: 0.375rem;
          }
        }
      }
      .table-view {
        padding: 3%;
        overflow-y: auto;
        padding-bottom: 8%;
        padding-top: 7%;

        .table-grouping {
          box-shadow: 0px 1px 3px 0px #00000014;
          width: 85%;
          background-color: #fff;
          border-radius: 0.5rem;

          justify-content: space-between;
          margin-bottom: 1.5rem;
          height: auto;
          padding-bottom: 1rem;
          .grouping-header {
            height: 1.5rem;
            display: flex;
            align-items: center;
            width: 96.5%;
            padding: 0.938rem 1.5rem;
            cursor: pointer;
            font-family: "Inter";
            font-size: 18px;
            font-weight: 600;
          }
          .grouping-table {
            margin-top: -1rem;
            margin: 0rem 1.5rem;
            .arrow {
              height: 0.75rem;
              width: 0.75rem;
              margin-top: 0.5rem;
              cursor: pointer;
              margin-left: 1rem;
            }
            .icons-container {
              display: flex;
              align-items: center;
              column-gap: 0.65rem;
              img {
                height: 0.875rem;
                width: 0.875rem;
              }
            }
            .dates {
              display: flex;
              column-gap: 1rem;
              flex-wrap: wrap;
              row-gap: 0.5rem;
              padding: 0.5rem;
              text-transform: capitalize;
            }
            .active {
              font-family: "Inter";
              font-size: 0.75rem;
              font-weight: 600;
              color: #20c94f;
              width: 4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #20c94f1a;
              border-radius: 0.25rem;
              height: 1.8rem;
            }
            .inactive {
              font-family: "Inter";
              font-size: 0.75rem;
              font-weight: 600;
              color: #e8361e;
              width: 4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #e8361e1a;
              border-radius: 0.25rem;
              height: 1.8rem;
            }
            .more-icon {
              position: relative;
              img {
                height: 1rem;
                width: 1rem;
              }
              .dropdown-content {
                width: 9rem;
                padding: 1rem 0.875rem;
                border-radius: 0.3rem;
                position: absolute;
                background-color: #f9f9f9;
                min-width: 160px;
                box-shadow: 0px 2px 6px 0px #0000001f;
                z-index: 1;
                margin-left: -0.75rem;
                border-radius: 0.25rem;
                left: 0;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                text-align: left;
                color: #19191c;
                cursor: pointer;
                align-items: center;
                column-gap: 1rem;
                .item {
                  line-height: 1.8rem;
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  img {
                    height: 0.9rem;
                    width: 0.9rem;
                  }
                }
                .item:hover {
                  color: #6f47eb;
                  img {
                    filter: brightness(0) saturate(100%) invert(26%) sepia(71%)
                      saturate(3300%) hue-rotate(246deg) brightness(96%)
                      contrast(91%);
                  }
                }
              }
            }
            #bookings {
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              text-align: left;
              color: #202020;
              width: 100%;
            }
            #shifts {
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              text-align: left;
              color: #202020;
              width: 100%;
            }
            #bookings td,
            #bookings th {
              padding: 1.125rem;
            }
            #shifts td,
            #shifts th {
              padding: 1.125rem;
            }
            #bookings tr:nth-child(even) {
              background-color: #f6f6f6;
            }
            #shifts tr:nth-child(even) {
              background-color: #f6f6f6;
            }
            #bookings th {
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              font-family: "Inter";
              font-size: 0.75rem;
              font-weight: 400;
              text-align: left;
              color: #84818a;
            }
            #shifts th {
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              font-family: "Inter";
              font-size: 0.75rem;
              font-weight: 400;
              text-align: left;
              color: #84818a;
            }
            #bookings th:first-child {
              max-width: 5rem;
            }
            #bookings td:first-child {
              max-width: 5rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            #bookings th:nth-child(2) {
              max-width: 6rem;
            }
            #bookings td:nth-child(2) {
              max-width: 6rem;
            }
            #bookings th:nth-child(3) {
              max-width: 12rem;
            }
            #bookings td:nth-child(3) {
              max-width: 12rem;
            }
            #bookings th:nth-child(4) {
              max-width: 4rem;
            }
            #bookings td:nth-child(4) {
              max-width: 4rem;
            }
            #bookings th:nth-child(5) {
              max-width: 3rem;
            }
            #bookings td:nth-child(5) {
              max-width: 3rem;
            }

            #shifts th:first-child {
              max-width: 5rem;
            }
            #shifts td:first-child {
              max-width: 5rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            #shifts th:nth-child(2) {
              max-width: 6rem;
            }
            #shifts td:nth-child(2) {
              max-width: 6rem;
            }
            #shifts th:nth-child(3) {
              max-width: 8rem;
            }
            #shifts td:nth-child(3) {
              max-width: 8rem;
            }
            #shifts th:nth-child(4) {
              max-width: 4rem;
            }
            #shifts td:nth-child(4) {
              max-width: 4rem;
            }
            #shifts th:nth-child(5) {
              max-width: 3rem;
            }
            #shifts td:nth-child(5) {
              max-width: 3rem;
            }
          }
          .empty-container {
            width: 100%;
            text-align: center;
            padding-top: 2%;
            padding-bottom: 2%;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #84818a;
            background-color: #f9f9f9;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            span {
              color: #19191c;
              margin-left: 0.5rem;
              font-weight: 500;
            }
          }
        }
        .create-table {
          box-shadow: 0px 1px 3px 0px #00000014;
          width: 50%;
          background-color: #fff;
          border-radius: 0.5rem;

          justify-content: space-between;
          margin-bottom: 1.5rem;
          height: auto;
          padding-bottom: 1rem;
          .grouping-header {
            height: 1.5rem;
            display: flex;
            align-items: center;
            padding: 0.938rem 1.5rem;
            cursor: pointer;
            font-family: "Inter";
            font-size: 1rem;
            font-weight: 500;
            border-bottom: 1px solid #e8e8e8;
          }
          .bottom-cont {
            padding: 0.938rem 1.5rem;
            .sub-header {
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 600;
              margin-bottom: 1.5rem;
            }
            .floor-type {
              height: 1.2rem;
              display: flex;
              align-items: center;
              width: 90%;
              border: 1px solid #e8e8e8;
              padding: 0.938rem 1.5rem;
              cursor: pointer;
              border-radius: 0.5rem;
              margin-bottom: 1rem;
              .left-shift-view {
                font-family: Inter;
                font-size: 0.875rem;
                font-weight: 500;
                text-align: left;
                width: 50%;
              }
              .right-shift-view {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .arrow-cont {
                  height: 1.5rem;
                  width: 1.5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 1rem;
                  img {
                    height: 0.75rem;
                    width: 0.75rem;
                  }
                }
              }
            }
            .floor-type-selectd {
              background: #00000024;
              .right-shift-view {
                .arrow-cont {
                  img {
                    transform: rotate(-180deg);
                  }
                }
              }
            }
            .tables {
              width: 88%;
              padding: 0rem 2rem;
              .table {
                font-family: Inter;
                font-size: 0.875rem;
                font-weight: 400;
                cursor: pointer;
                padding: 0.8rem 1rem;
                margin-bottom: 0.4rem;
                border-radius: 0.25rem;
                color: #202020;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .check {
                  height: 1.5rem;
                  width: 1.5rem;
                  border: 1px solid #0000001a;
                  border-radius: 50%;
                }
                .checked {
                  height: 1.5rem;
                  width: 1.5rem;
                  background-color: #1f7b24;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    height: 0.7rem;
                    width: 0.7rem;
                    filter: brightness(0) saturate(100%) invert(100%) sepia(1%)
                      saturate(2381%) hue-rotate(150deg) brightness(103%)
                      contrast(100%);
                  }
                }
              }
              .table-selected {
                background-color: #e5f1e5;
              }
            }
            .btn-cont {
              display: flex;
              justify-content: flex-end;
              margin-top: 2rem;
            }
          }
        }
      }
    }
    .layout-view {
      overflow: hidden;
      height: 91vh;
      width: 100%;
      display: flex;
      position: relative;
      margin-top: 4.05rem;
      ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 5px;
        border-radius: 5px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: #dfdfdf;
      }
      .left-view {
        width: 24%;
        height: 100%;
        background-color: transparent;
        border-right: 1px solid #ededed;
        position: relative;
        overflow: auto;
        background-color: #fff;
        .fixed-view {
          position: fixed;
          width: 22.7%;
          z-index: 10;
          .header-container {
            display: flex;
            padding-top: 1.375rem;
            width: 100%;
            border-bottom: 1px solid #ededed;
            background-color: #fff;

            .content {
              width: inherit;
              text-align: center;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 600;
              color: #84818a;
              padding-bottom: 0.75rem;
            }
            .content-selected {
              color: #6f47eb;

              border-bottom: 2px #6f47eb solid;
            }
          }
        }
        .table-view {
          padding-bottom: 20%;
          padding-top: 5rem;
          text-align: center;
          border-bottom: 1px solid #ebeaed;
          .header {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 20px;
          }
          .sub {
            font-family: "Inter";
            font-size: 0.75rem;
            font-weight: 400;
            color: #5c5f62;
          }
          .table-layout {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3.313rem;
            column-gap: 1.875rem;
          }
        }
      }
      .right-view {
        width: 76%;
        overflow-y: auto;
        overflow-x: auto;
        position: relative;
        padding-bottom: 3%;

        .floating {
          position: fixed;
          bottom: 2rem;
          width: 50%;
          right: 8%;
          background-color: #000;
          height: 36px;
          font-family: "Inter";
          font-size: 0.75rem;
          font-weight: 400;
          color: #ffffff;
          border-radius: 0.25rem;
          display: flex;
          padding-right: 2.5%;

          .item {
            width: 25%;
            min-width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 1.2rem;
            img {
              height: 1rem;
              width: 1rem;
            }
          }
        }
        .FloorView {
          width: 100%;
          position: relative;
          padding-bottom: 2%;

          .header-container {
            display: flex;
            // padding-top: 1.375rem;
            width: 100%;
            border-bottom: 1px solid #ededed;
            background-color: #ffffff !important;
            position: fixed;
            top: 4rem;
            z-index: 10;
            height: 3.375rem;
            .arrow-container {
              display: flex;
              border: 1px solid #e4e4e4;
              height: 1.8rem;
              margin-top: 0.8rem;
              border-radius: 2rem;
              margin-left: 1rem;
              margin-right: 1rem;
              .arrow-left {
                width: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #e4e4e4;
                .img-arr {
                  height: 0.7rem;
                  transform: rotate(180deg);
                  cursor: pointer;
                }
                .img-arr-filter {
                  height: 0.7rem;
                  transform: rotate(180deg);

                  filter: brightness(0) saturate(100%) invert(99%) sepia(0%)
                    saturate(4579%) hue-rotate(168deg) brightness(119%)
                    contrast(86%);
                }
              }
              .arrow-right {
                width: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .img-arr {
                  height: 0.7rem;
                  cursor: pointer;
                }
                .img-arr-filter {
                  height: 0.7rem;
                  filter: brightness(0) saturate(100%) invert(99%) sepia(0%)
                    saturate(4579%) hue-rotate(168deg) brightness(119%)
                    contrast(86%);
                }
              }
            }
            .empty-arrow-cont {
              width: 5rem;
              margin-left: 1rem;
            }

            .rooms {
              display: flex;
              width: 52%;
              height: inherit;
              align-items: flex-end;
              overflow-x: hidden;
              .cont {
                display: flex;
                align-items: center;

                justify-content: center;
                .floor-container-l {
                  width: 180px;
                  min-width: 180px;
                  max-width: 180px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .content-r {
                    width: 164px;
                    text-align: center;
                    font-family: "Inter";
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: #84818a;
                    padding-bottom: 0.75rem;
                    padding-left: 1.25rem;
                    padding-right: 1.25rem;
                    cursor: pointer;
                  }
                  .content-selected-r {
                    color: #2e2c34;
                    border-bottom: 2px #6f47eb solid;
                  }
                  .menu-ic {
                    height: 1rem;
                    min-width: 1rem;
                    margin-bottom: 0.5rem;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .menu {
                      height: 0.875rem;
                    }
                  }
                }
              }
              .dropdown {
                width: 207px;
                border: 1px solid #e4e4e4;

                border-radius: 0.3rem;
                position: absolute;
                background-color: #fff;
                box-shadow: 0px 2px 6px 0px #00000013;
                z-index: 1;
                border-radius: 0.5rem;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
                color: #5c5f62;
                cursor: pointer;
                margin-top: 0.2rem;
                top: 3rem;
                .item {
                  padding: 0.6rem 1.2rem;
                  display: flex;
                  align-items: center;
                  column-gap: 0.625rem;
                  img {
                    height: 1rem;
                    width: 1rem;
                  }
                }
                .item:hover {
                  color: #6f47eb;
                  background-color: #f9f9f9;
                  border-radius: 0.3rem;
                  img {
                    filter: brightness(0) saturate(100%) invert(49%) sepia(86%)
                      saturate(6786%) hue-rotate(245deg) brightness(96%)
                      contrast(92%);
                  }
                }
              }
            }
            .btn-container {
              display: flex;
              align-items: center;
              column-gap: 0.75rem;
              width: 18%;
              .sec-btn {
                border: 1.2px solid #6f47eb;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 20px;
                color: #6f47eb;
                height: 34px;
                width: 106px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                cursor: pointer;
                column-gap: 0.3rem;
                img {
                  height: 0.75rem;
                  width: 0.75rem;
                }
              }
            }
          }
          .floor-l {
            height: 540px;
            width: 1000px;
            margin: 2%;
            box-shadow: 0px 4px 4px 0px #0000001a;
            border-radius: 0.25rem;
            background-image: radial-gradient(#dddddd 0.063rem, transparent 0);
            background-size: 1.2rem 1.2rem;
            position: relative;
            margin-top: 5rem;
            background-color: #fff;
            // margin-bottom: -5rem;
          }
        }
      }
    }
    .restriction-view {
      margin-top: 4.05rem;
      .restriction-top-bar {
        height: 4rem;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #ebeaed;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        .left-container {
          width: 48%;
          display: flex;
          align-items: center;
          font-family: Inter;
          font-size: 0.875rem;
          font-weight: 400;
        }
        .right-container {
          width: 47%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .block-hours-btn {
            font-family: Inter;
            font-size: 0.75rem;
            font-weight: 500;
            margin-right: 5rem;
            background-color: #6f47eb;
            color: #fff;
            padding: 0.625rem 0.75rem;
            border-radius: 0.375rem;
            cursor: pointer;
          }
        }
      }
      .restriction-table-view {
        padding: 3%;
        overflow-y: auto;
        padding-bottom: 8%;
        padding-top: 6rem;
        .restriction-table {
          box-shadow: 0px 1px 3px 0px #00000014;
          width: 80%;
          background-color: #fff;
          border-radius: 0.5rem;
          .more-icon {
            position: relative;
            img {
              height: 0.75rem;
              width: 0.75rem;
            }
            .dropdown-content {
              width: 12rem;
              padding: 1rem 0.875rem;
              border-radius: 0.3rem;
              position: absolute;
              background-color: #f9f9f9;
              min-width: 160px;
              box-shadow: 0px 2px 6px 0px #0000001f;
              z-index: 1;
              margin-left: -0.75rem;
              border-radius: 0.25rem;
              left: 0;
              font-family: "Inter";
              font-size: 0.85rem;
              font-weight: 400;
              text-align: left;
              color: #5c5f62;
              cursor: pointer;
              align-items: center;
              column-gap: 1rem;
              .item {
                line-height: 1.8rem;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                img {
                  height: 0.9rem;
                  width: 0.9rem;
                  filter: brightness(0) saturate(100%) invert(35%) sepia(0%)
                    saturate(512%) hue-rotate(256deg) brightness(99%)
                    contrast(84%);
                }
              }
              .item:hover {
                color: #6f47eb;
                img {
                  filter: brightness(0) saturate(100%) invert(26%) sepia(71%)
                    saturate(3300%) hue-rotate(246deg) brightness(96%)
                    contrast(91%);
                }
              }
            }
          }
          #bookings {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            text-align: left;
            color: #202020;
            width: 100%;
          }
          #bookings td,
          #bookings th {
            padding: 1.125rem;
            text-align: center;
          }
          #bookings tr:nth-child(even) {
            background-color: #f6f6f6;
          }
          #bookings th {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            font-family: "Inter";
            font-size: 0.75rem;
            font-weight: 400;
            text-align: center;
            color: #84818a;
          }
          .empty-container {
            width: 100%;
            text-align: center;
            padding-top: 2%;
            padding-bottom: 2%;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #84818a;
            background-color: #f9f9f9;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            span {
              color: #19191c;
              margin-left: 0.5rem;
              font-weight: 500;
            }
          }
        }
      }
    }
    .payment-rules-view {
      margin-top: 4.05rem;
      .payment-rules-top-bar {
        height: 4rem;
        width: 100%;
        background-color: #fff;
        border-bottom: 1px solid #ebeaed;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        .left-container {
          width: 48%;
          display: flex;
          align-items: center;
          font-family: Inter;
          font-size: 0.875rem;
          font-weight: 400;
        }
        .right-container {
          width: 47%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .left-container-right {
            margin-right: 5rem;
            display: flex;
            align-items: center;
            column-gap: 0.75rem;
            .block-hours-btn {
              font-family: Inter;
              font-size: 0.75rem;
              font-weight: 500;
              background-color: #6f47eb;
              color: #fff;
              padding: 0.625rem 0.75rem;
              border-radius: 0.375rem;
              cursor: pointer;
            }
            .search-container {
              width: 11.5rem;
              background-color: #fff;
              padding: 0.5rem 0.75rem;
              border: 1.2px solid #00000024;
              border-radius: 0.375rem;
            }
          }
        }
      }
      .payment-rules-table-view {
        padding: 3%;
        overflow-y: auto;
        padding-bottom: 8%;
        padding-top: 6rem;
        .payment-rules-table {
          box-shadow: 0px 1px 3px 0px #00000014;
          width: 90%;
          background-color: #fff;
          border-radius: 0.5rem;
          margin-bottom: 2rem;
          .table-header {
            font-family: Inter;
            font-size: 1rem;
            font-weight: 600;
            padding: 1.5rem;
          }
          .active {
            font-family: "Inter";
            font-size: 0.75rem;
            font-weight: 600;
            color: #20c94f;
            width: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #20c94f1a;
            border-radius: 0.25rem;
            height: 1.8rem;
          }
          .inactive {
            font-family: "Inter";
            font-size: 0.75rem;
            font-weight: 600;
            color: #e8361e;
            width: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e8361e1a;
            border-radius: 0.25rem;
            height: 1.8rem;
          }

          .more-icon {
            position: relative;
            img {
              height: 1rem;
              width: 1rem;
            }
            .dropdown-content {
              width: 9rem;
              padding: 1rem 0.875rem;
              border-radius: 0.3rem;
              position: absolute;
              background-color: #f9f9f9;
              min-width: 160px;
              box-shadow: 0px 2px 6px 0px #0000001f;
              z-index: 1;
              margin-left: -0.75rem;
              border-radius: 0.25rem;
              left: 0;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              text-align: left;
              color: #19191c;
              cursor: pointer;
              align-items: center;
              column-gap: 1rem;
              .item {
                line-height: 1.8rem;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                img {
                  height: 0.9rem;
                  width: 0.9rem;
                }
              }
              .item:hover {
                color: #6f47eb;
                img {
                  filter: brightness(0) saturate(100%) invert(26%) sepia(71%)
                    saturate(3300%) hue-rotate(246deg) brightness(96%)
                    contrast(91%);
                }
              }
            }
          }
          #bookings {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            text-align: left;
            color: #202020;
            width: 100%;
          }
          #bookings td,
          #bookings th {
            padding: 1.125rem;
            text-align: center;
          }

          #bookings tr:nth-child(even) {
            background-color: #f6f6f6;
          }
          #bookings td:nth-child(6) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          #bookings th {
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            font-family: "Inter";
            font-size: 0.75rem;
            font-weight: 400;
            text-align: center;
            color: #84818a;
          }
          .empty-container {
            width: 100%;
            text-align: center;
            padding-top: 2%;
            padding-bottom: 2%;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #19191c;
            background-color: #f9f9f9;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            span {
              color: #19191c;
              margin-left: 0.5rem;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
