@import "../../colors/colors.scss";
.Baseheader {
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  column-gap: 1rem;
  background-size: 100% 100%;
  background-color: rgba(27, 27, 27, 0.342);
  background-blend-mode: lighten;
  .logo-container {
    height: 6rem;
    width: 6rem;
    background-color: $white;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 6rem;
    }
  }
  .details {
    .header {
      font-family: "Inter";
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.2rem;
      color: $white;
      text-align: left;
    }
    .sub-header {
      font-family: "Inter";
      font-size: 0.8rem;
      font-weight: 300;
      line-height: 1.5rem;
      color: $white;
      text-align: left;
    }
  }
}
