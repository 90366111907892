.shift-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 1010;
  overflow-y: auto;
  padding: 4%;
}

.shift-modal-content {
  background: white;
  border-radius: 0.5rem;
  width: 510px;
  animation: zoomIn 0.3s ease-in-out;
  margin: auto;
  .error {
    color: red;
    font-family: "Inter";
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
  .alert-warning {
    width: 90%;
    margin: auto;
    border: 1px solid #f7e1a1;
    background-color: #fdf8e8;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: start;
    margin-top: 1rem;

    .left-content {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #5c5f62;
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem;
        width: 1rem;
        background-color: #deaf21;
        border-radius: 50%;
        display: flex;
        align-items: start;
        column-gap: 0.5rem;
        justify-content: center;
        .danger {
          height: 0.5rem;
          width: 0.5rem;
        }
        .warning {
          height: 0.85rem;
          width: 0.85rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
    .close {
      cursor: pointer;
      height: 0.75rem;
      width: 0.75rem;
      filter: brightness(0) saturate(100%) invert(59%) sepia(4%) saturate(5%)
        hue-rotate(356deg) brightness(85%) contrast(82%);
      margin-left: 0.2rem;
    }
  }
  .alert-danger {
    width: 95%;
    margin: auto;
    border: 1px solid #ebadad;
    background-color: #faebeb;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .left-content {
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem !important;
        width: 1rem !important;
        background-color: #cc3333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        img {
          height: 0.5rem;
          width: 0.5rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
  .container {
    padding: 2.625rem;
    border-bottom: 3px solid #e8e8e8;
    .header {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
      }
    }

    .content {
      width: 94%;
      margin-top: 2rem;
      .active {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        margin-bottom: 2rem;
        align-items: center;
        .switch {
          position: relative;
          display: inline-block;
          width: 44px;
          height: 24px;
          margin-right: -2rem !important;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
          border-radius: 34px;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 2px;
          bottom: 2.05px;
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }

        input:checked + .slider {
          background-color: #6f47eb;
        }

        input:checked + .slider:before {
          transform: translateX(20px);
        }

        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }

        .slider.round:before {
          border-radius: 50%;
        }
      }
      .shift-selection {
        margin-top: 2rem;
        .header-tp {
          color: #444444;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          line-height: 1.2rem;
          margin-bottom: 1.5rem;
          display: flex;
        }
        .shift-types {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 0.25rem 0.313rem;
          border: 1px solid #e8e8e8;
          border-radius: 0.5rem;
          .type {
            width: 50%;
            height: 2.625rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Inter;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 18.2px;
            color: #202020;
            cursor: pointer;
            border-radius: 0.375rem;
          }
          .selected {
            background: #eee9fd;
          }
        }
      }
      .date-container {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
      }
      .shift-days {
        margin-top: 2rem;

        .header-tp {
          color: #444444;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          line-height: 1.2rem;
          margin-bottom: 1.5rem;
          display: flex;
          .alert {
            cursor: pointer;
            height: 1.125rem;
            width: 1.125rem;
            background-color: #deaf21;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
            img {
              height: 0.8rem;
              width: 0.8rem;
            }
          }
        }
        .closed {
          margin-top: -0.5rem;
          margin-bottom: 1.5rem;
        }
        .shifts-cont {
          display: flex;
          column-gap: 0.8rem;
          .shift {
            height: 2.813rem;
            width: 2.813rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Inter;
            font-size: 0.791rem;
            font-weight: 500;
            border: 0.9px solid #e4e4e4;
            border-radius: 50%;
            cursor: pointer;
            text-transform: capitalize;
          }
          .shift-selected {
            height: 2.813rem;
            width: 2.813rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Inter;
            font-size: 0.791rem;
            font-weight: 500;
            border: 0.9px solid #6f47eb;
            border-radius: 50%;
            background-color: #eee9fd;
            color: #6f47eb;
            cursor: pointer;
            text-transform: capitalize;
          }
          .shift-disable {
            height: 2.813rem;
            width: 2.813rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Inter;
            font-size: 0.791rem;
            font-weight: 500;
            border: 0.9px solid #fafafa;
            border-radius: 50%;
            background-color: #fafafa;
            color: #aaa;
            text-transform: capitalize;
            cursor: not-allowed;
          }
        }
      }
      .time-container {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        .start {
          select {
            margin-top: 0.5rem;
            width: 11.25rem;
            padding: 0.6rem 1rem;
            font-size: 1rem;
            border-radius: 0.25rem;
            border: 1px solid #e8e8e8;
            outline: none;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #202020;
            -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
            -moz-appearance: none; /* Remove default arrow in Firefox */
            appearance: none; /* Remove default arrow in other browsers */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="gray"/></svg>');
            background-repeat: no-repeat;
            background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
            background-size: 10px auto, 100%;
          }
        }
      }
      .type-container {
        margin-top: 2rem;
        color: #444444;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 1.2rem;
        .input {
          margin-top: 0.5rem;
          width: 9.2rem;
          padding: 0.6rem 1rem;
          border-radius: 0.25rem;
          border: 1px solid #e8e8e8;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
        }
      }
    }
    .btn-cont {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      column-gap: 2rem;
    }
  }
  .config {
    padding: 2.625rem;
    .time-container {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;
      color: #444444;
      font-family: "Inter";
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.2rem;
      .start {
        .number {
          margin-top: 0.5rem;
          width: 9rem;
          padding: 0.6rem 1rem;
          font-size: 1rem;
          border-radius: 0.25rem;
          border: 1px solid #e8e8e8;
          outline: none;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
          input {
            outline: none;
            border: none;
          }
        }
        select {
          margin-top: 0.5rem;
          width: 11.25rem;
          padding: 0.6rem 1rem;
          font-size: 1rem;
          border-radius: 0.25rem;
          border: 1px solid #e8e8e8;
          outline: none;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
          -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
          -moz-appearance: none; /* Remove default arrow in Firefox */
          appearance: none; /* Remove default arrow in other browsers */
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="gray"/></svg>');
          background-repeat: no-repeat;
          background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
          background-size: 10px auto, 100%;
        }
      }
    }
    .duration-container {
      display: flex;
      margin-top: 2rem;
      color: #444444;
      font-family: "Inter";
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.2rem;
      .start {
        .input {
          margin-top: 0.5rem;
          width: 9.2rem;
          padding: 0.6rem 1rem;
          border-radius: 0.25rem;
          border: 1px solid #e8e8e8;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
          background-color: #ededed;
        }
        .number {
          margin-top: 0.5rem;
          width: 9rem;
          padding: 0.6rem 1rem;
          font-size: 1rem;
          border-radius: 0.25rem;
          border: 1px solid #e8e8e8;
          outline: none;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
          input {
            outline: none;
            border: none;
          }
        }
        select {
          margin-top: 0.5rem;
          width: 11.25rem;
          padding: 0.6rem 1rem;
          font-size: 1rem;
          border-radius: 0.25rem;
          border: 1px solid #e8e8e8;
          outline: none;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
          -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
          -moz-appearance: none; /* Remove default arrow in Firefox */
          appearance: none; /* Remove default arrow in other browsers */
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="gray"/></svg>');
          background-repeat: no-repeat;
          background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
          background-size: 10px auto, 100%;
        }
      }
      .end {
        display: flex;
        margin-left: 2rem;
        .left {
          width: 75%;
          .text {
            font-family: Inter;
            font-size: 0.75rem;
            font-weight: 400;
            color: #84818a;
            margin-top: 0.8rem;
          }
          .switch {
            position: relative;
            display: inline-block;
            width: 44px;
            height: 24px;
          }

          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
            border-radius: 34px;
          }

          .slider:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 2px;
            bottom: 2.05px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
          }

          input:checked + .slider {
            background-color: #6f47eb;
          }

          input:checked + .slider:before {
            transform: translateX(20px);
          }

          /* Rounded sliders */
          .slider.round {
            border-radius: 34px;
          }

          .slider.round:before {
            border-radius: 50%;
          }
        }
        .right {
          width: 25%;
          .alert {
            cursor: pointer;
            height: 1.2rem;
            width: 1.2rem;
            background-color: #deaf21;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 1rem;
              width: 1rem;
            }
          }
        }
      }
    }
    .header {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
    }
    .advance-settings {
      border-top: 3px solid #e8e8e8;
      width: 100%;
      margin-left: -9.7%;
      padding: 2.625rem;
      margin-top: 1.875rem;
      .head-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .head {
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          column-gap: 12px;
          .check {
            height: 1rem;
            width: 1rem;
            border: 1px solid #cccccc;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .checked {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 1rem;
              width: 1rem;
              background: #6f47eb;

              img {
                height: 0.7rem;
                width: 0.7rem;
                filter: brightness(0) saturate(100%) invert(100%) sepia(8%)
                  saturate(29%) hue-rotate(228deg) brightness(107%)
                  contrast(99%);
                margin: 0;
              }
            }
            img {
              height: 1rem;
              width: 1rem;
            }
          }
        }
        .add-advance {
          height: 1.125rem;
          width: 1.125rem;
          cursor: pointer;
        }
      }
      .content-window {
        margin-top: 1.75rem;
        .time-window-container {
          width: 96%;
          padding-left: 0.625rem;
        }
      }
    }
    .btn-cont {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 1rem;
      column-gap: 2rem;
    }
  }
}
.shift-warning-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 1010;
  overflow-y: auto;
  padding: 4%;
}

.shift-warning-modal-content {
  background: #f9f9f9;
  border-radius: 0.375rem;
  width: 470px;
  animation: zoomIn 0.3s ease-in-out;
  margin: auto;
  padding: 26px 20px;

  .header-warn {
    font-family: "Inter";
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    img {
      height: 2rem;
      width: 2rem;
    }
  }
  .sub {
    font-family: "Inter";
    font-size: 0.875rem;
    font-weight: 400;
    color: #5c5f62;
    margin-top: 1.5rem;
  }
  .conflict {
    margin-top: 1.25rem;
    .head {
      font-family: "Inter";
      font-size: 16px;
      font-weight: 600;
      color: #202020;
    }
    .table {
      width: 90%;
      background-color: #ffffff;
      box-shadow: 0px 1px 3px 0px #00000014;
      padding: 10px 20px;
      border-radius: 0.5rem;
      margin: auto;
      margin-top: 1.25rem;
      .switch {
        position: relative;
        display: inline-block;
        width: 44px;
        height: 24px;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        border-radius: 34px;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2.05px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
      }

      input:checked + .slider {
        background-color: #6f47eb;
      }

      input:checked + .slider:before {
        transform: translateX(20px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
      .active {
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 600;
        color: #20c94f;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #20c94f1a;
        border-radius: 0.25rem;
        height: 1.8rem;
      }
      .inactive {
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 600;
        color: #e8361e;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e8361e1a;
        border-radius: 0.25rem;
        height: 1.8rem;
      }
      #shifts {
        font-family: "Inter";
        font-size: 0.875rem;
        font-weight: 400;
        text-align: left;
        color: #202020;
        width: 100%;
      }

      #shifts td,
      #shifts th {
        padding: 1.125rem 0rem;
      }
      #shifts td {
        border-bottom: 1px solid #e8e8e8a4;
      }
      #shifts td:last-child {
        border-bottom: 1px solid #e8e8e8a4;
      }
      #shifts th {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 400;
        text-align: left;
        color: #84818a;
      }
    }
  }
  .btn-cont {
    margin-top: 36px;
    display: flex;
    justify-content: flex-end;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
