.TopBar {
  background-color: #fff;
  height: 4rem;
  width: 100%;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  .left-container {
    margin-left: -5%;
    width: 30%;
    display: flex;
    font-family: "Inter";
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    align-items: center;
    column-gap: 0.75rem;
    img {
      height: 0.9rem;
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }
  .mid-container {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right-container {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 0.5rem;
    .icon-cont {
      height: 1.2rem;
      width: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        height: 1rem;
        width: 1rem;
      }
    }
    .user-dropdown {
      position: relative;
      display: inline-block;
      .profile-icon {
        font-family: "Inter";
        font-size: 0.938rem;
        font-weight: 600;
        color: #5c5f62;
        height: 2.25rem;
        width: 2.25rem;
        background-color: #ebddca;
        border-radius: 50%;
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
      }

      .dropdown-content {
        width: 30%;
        border: 1px solid #e4e4e4;
        padding: 1rem 0.875rem;
        border-radius: 0.3rem;
        display: flex;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 2px 6px 0px #0000001f;
        z-index: 1;
        margin-left: -0.75rem;
        border-radius: 0.5rem;
        left: -160px;
        font-family: "Inter";
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 18px;
        text-align: left;
        color: #19191c;
        cursor: pointer;
        align-items: center;
        column-gap: 1rem;
        img {
          height: 0.9rem;
          width: 0.9rem;
        }
      }
      .dropdown-content:hover {
        background-color: #eee9fd;
      }
    }
  }
}
