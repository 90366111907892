.comment-modal-content {
  background: white;
  position: absolute;
  width: 22rem;
  border: 1px solid #ebebeb;
  height: 91vh;
  margin: auto;
  left: 27.7%;
  z-index: 1000;
  top: 0;
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    img {
      height: 1rem;
      width: 1rem;
      filter: brightness(0) saturate(100%) invert(4%) sepia(2%) saturate(4659%)
        hue-rotate(201deg) brightness(99%) contrast(87%);
      cursor: pointer;
    }
  }
  .content {
    background: white;
    width: 85%;
    margin-left: 3%;
  }
}
