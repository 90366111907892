.date-modal-content {
  background: white;
  position: absolute;
  width: 18rem;
  border: 1px solid #ebebeb;
  height: 91vh;
  margin: auto;
  left: 27.7%;
  z-index: 1000;
  top: 0;
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    img {
      height: 1rem;
      width: 1rem;
      filter: brightness(0) saturate(100%) invert(4%) sepia(2%) saturate(4659%)
        hue-rotate(201deg) brightness(99%) contrast(87%);
      cursor: pointer;
    }
  }
  .content {
    background: white;

    overflow: auto;
    height: 85vh;
    padding-left: 50%;
  }
  .react-datepicker__input-container input {
    font-family: "Inter";
    font-size: 0.9rem;
    font-weight: 500;
    border: none;
    outline: none;
    width: 6rem;
    text-align: center;
    display: none;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    input {
      font-family: "Inter";
      font-size: 0.9rem;
      font-weight: 500;
      border: none;
      outline: none;
      width: 6rem;
      text-align: center;
    }
  }
  .react-datepicker {
    border: 1px solid #0000000d !important;
    box-shadow: 0px 3px 6px 0px #17254c1f;
  }
  .react-datepicker__header {
    text-align: center;
    background-color: #fff !important;
    border: none !important;
  }
  .react-datepicker__day-name {
    color: #7b8199 !important;
    font-size: 1rem !important;
  }
  .react-datepicker__day-names {
    white-space: nowrap;
    margin-bottom: -8px;
    margin-top: 1rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-family: "Inter";
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border: none !important;
    background: linear-gradient(50.47deg, #6f47eb 0%, #6f47eb 83.75%);
  }
}
