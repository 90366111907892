/* src/Modal.css */
.FloorDetails-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010;
}

.FloorDetails-content {
  background: white;
  border-radius: 0.5rem;
  position: relative;
  width: 80%;

  .header-container-new {
    display: flex;
    border-bottom: 1px solid #ededed;
    padding: 1rem 1.5rem;
    align-items: center;
    height: max-content;
    justify-content: space-between;
    .header {
      font-family: "Inter";
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: -0.01em;
      display: flex;
      align-items: center;
      column-gap: 0.75rem;
      img {
        height: 0.813rem;
        width: 0.813rem;
        cursor: pointer;
        filter: brightness(0) saturate(100%) invert(38%) sepia(3%)
          saturate(598%) hue-rotate(169deg) brightness(91%) contrast(85%);
        transform: rotate(-90deg);
      }
    }
  }
  .profile-details {
    display: flex;
    border-bottom: 1px solid #ededed;
    padding: 1rem 1.5rem;
    justify-content: space-between;
    align-items: center;

    .profile {
      display: flex;
      align-items: center;
      column-gap: 1.438rem;
      .avatar {
        height: 2.75rem;
        width: 2.75rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        background-color: #dfdfdf;
        img {
          height: 2.75rem;
          width: 2.75rem;
          border-radius: 50%;
          object-fit: cover;
        }
        .edit {
          position: absolute;
          height: 1.2rem;
          width: 1.2rem;
          background-color: #6f47eb;
          border-radius: 50%;
          border: 2px solid #fff;
          right: -0.3rem;
          bottom: -0.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 0.75rem;
            width: 0.75rem;
          }
        }
      }
      .name-conatiner {
        .name {
          font-family: "Inter";
          font-size: 1rem;
          font-weight: 600;
          color: #202020;
        }
        .email {
          font-family: "Inter";
          font-size: 0.75rem;
          font-weight: 400;
          color: #484848;
          display: flex;
          column-gap: 0.3rem;
          align-items: center;
          margin-top: 0.1rem;
          img {
            height: 0.75rem;
            width: 0.75rem;
          }
        }
      }
    }
    .more {
      height: 1.25rem;
      width: 1.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        height: 1rem;
        width: 1rem;
      }
    }
  }
  .tab-bar {
    display: flex;
    border-bottom: 1px solid #ededed;
    padding-top: 1rem;
    .item {
      font-family: "Inter";
      font-size: 0.85rem;
      font-weight: 500;
      text-align: center;
      color: #84818a;
      padding: 0.75rem 2.75rem;
      cursor: pointer;
    }
    .selected {
      color: #6f47eb;
      border-bottom: 2px #6f47eb solid;
      font-weight: 600;
    }
  }
  .container {
    height: 60vh;
    background-color: #f9f9f9;
    padding-bottom: 2rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    display: flex;
    .left-container {
      width: 35%;
      padding: 0.5%;
      margin: 1%;
      overflow-y: auto;
      overflow-x: hidden;
      .inner-container {
        background-color: #fff;
        border: 1px solid #0000001a;
        border-radius: 0.25rem;
        height: auto;
        .alert {
          .alert-danger {
            width: 78%;
            margin: auto;
            border: 1px solid #ebadad;
            background-color: #faebeb;
            padding: 0.75rem;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            margin-top: 1rem;

            .left-content {
              width: 10%;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              .cancel {
                height: 1rem !important;
                width: 1rem !important;
                background-color: #cc3333;
                border-radius: 50%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                justify-content: center;
                img {
                  height: 0.5rem;
                  width: 0.5rem;
                }
              }
            }
            .right-content {
              width: 90%;
              display: flex;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              color: #1a1a1a;
            }
          }
          .alert-warning {
            width: 78%;
            margin: auto;
            border: 1px solid #f7e1a1;
            background-color: #fdf8e8;
            padding: 0.75rem;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            margin-top: 1rem;

            .left-content {
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              color: #1a1a1a;
              width: 10%;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              .cancel {
                height: 1rem;
                width: 1rem;
                background-color: #deaf21;
                border-radius: 50%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                justify-content: center;
                .danger {
                  height: 0.5rem;
                  width: 0.5rem;
                }
                .warning {
                  height: 0.85rem;
                  width: 0.85rem;
                }
              }
            }
            .right-content {
              width: 90%;
              display: flex;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              color: #1a1a1a;
            }
          }
          .alert-warning-2 {
            width: 78%;
            margin: auto;
            border: 1px solid #f7e1a1;
            background-color: #fdf8e8;
            padding: 0.75rem;
            border-radius: 0.25rem;
            display: flex;
            align-items: center;
            margin-top: 1rem;

            .left-content {
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              color: #1a1a1a;
              width: 10%;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              .cancel {
                height: 1rem;
                width: 1rem;
                background-color: #deaf21;
                border-radius: 50%;
                display: flex;
                align-items: center;
                column-gap: 0.5rem;
                justify-content: center;
                .danger {
                  height: 0.5rem;
                  width: 0.5rem;
                }
                .warning {
                  height: 0.85rem;
                  width: 0.85rem;
                }
              }
            }
            .right-content {
              width: 90%;
              display: flex;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              color: #1a1a1a;
            }
          }
        }

        .item {
          border: #e8e8e8 1px solid;

          margin: 0.5rem 1rem;
          padding: 1rem 1.125rem;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          position: relative;
          border-radius: 0.5rem;
          .det {
            display: flex;
            align-items: center;
            column-gap: 2.5rem;
            width: 100%;
            .head {
              display: flex;
              align-items: center;
              column-gap: 0.75rem;
              color: #000;
              width: 150px;
              img {
                height: 0.75rem;
                width: 0.75rem;
              }
            }
            .sub {
              font-family: "Inter";
              font-size: 0.8rem;
              font-weight: 500;
              color: #202020;
              width: 70%;
            }
            .sub-gr {
              font-family: "Inter";
              font-size: 0.8rem;
              font-weight: 500;
              color: #84818a;
              width: 70%;
            }
          }
          .arrow-cont {
            img {
              height: 0.75rem;
              width: 0.75rem;
              transform: rotate(90deg);
            }
          }
        }
        .item-def {
          border: #e8e8e8 1px solid;
          padding-left: 1.125rem;
          margin: 0.5rem 1rem;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          position: relative;
          border-radius: 0.5rem;
          .det {
            display: flex;
            align-items: center;
            column-gap: 2.5rem;
            width: 100%;
            .head {
              display: flex;
              align-items: center;
              column-gap: 0.75rem;
              color: #000;
              width: 150px;
              img {
                height: 0.75rem;
                width: 0.75rem;
              }
            }
            .sub {
              font-family: "Inter";
              font-size: 0.8rem;
              font-weight: 500;
              color: #202020;
              width: 70%;
            }
            .sub-gr {
              font-family: "Inter";
              font-size: 0.8rem;
              font-weight: 500;
              color: #84818a;
              width: 70%;
            }
          }
          .arrow-cont {
            img {
              height: 0.75rem;
              width: 0.75rem;
              transform: rotate(90deg);
            }
          }
        }
        .item-sel {
          background: #f5f1ff;
        }
      }
    }
    .right-container {
      width: 65%;
      padding: 0.5%;
      margin: 1%;
      overflow: auto;
      .inner-container {
        background-color: #fff;
        border: 1px solid #0000001a;
        border-radius: 0.25rem;
        height: auto;
        .det {
          display: flex;
          align-items: center;
          column-gap: 2.5rem;
          width: 100%;
          .head {
            display: flex;
            align-items: center;
            column-gap: 0.75rem;
            color: #000;
            width: 20%;
            img {
              height: 0.75rem;
              width: 0.75rem;
            }
          }
          .sub {
            font-family: "Inter";
            font-size: 0.8rem;
            font-weight: 400;
            color: #84818a;
          }
        }
        .item {
          padding: 1.188rem;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          border-bottom: #e8e8e8 1px solid;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .arrow-cont {
            img {
              height: 0.75rem;
              width: 0.75rem;
              transform: rotate(90deg);
            }
          }
        }
        .item-sel {
          background: #f5f1ff;
        }
        .content {
          background: white;
          width: 85%;
          margin-left: 3%;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #dfdfdf;
  }
}
