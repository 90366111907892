@import "../../colors/colors.scss";

.TextArea {
  display: block;

  .label {
    margin-bottom: 0.5rem;
    color: #444444;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.2rem;
    margin-left: 0.5rem;
  }

  textarea {
    width: 100%;
    min-height: 4rem;
    padding: 0.6rem 1rem;
    font-size: 0.938rem;
    border-radius: 0.25rem;
    border: 1px solid $border-grey;
    outline: none;
    font-family: "Inter";
    font-weight: 400;
    line-height: 1.2rem;
    color: #202020;
    resize: none;
  }
}
