.NetworkStatus {
  display: flex;
  align-items: center;
  column-gap: 20px;
  position: fixed;
  top: 0.625rem;
  right: 0.625rem;
  padding: 15px 20px;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  font-family: "Inter";
  z-index: 10000;
  color: rgb(0, 0, 0);
  background: #fff;
  border: 1px solid #ebeaed;
  font-weight: 500;
  box-shadow: 0px 3px 3px 0px #00000014;
  animation: slideRight 0.3s forwards;
  width: 300px;
  img {
    height: 20px;
    width: 20px;
  }
  .sub-text {
    font-weight: 400;
    font-size: 0.75rem;
    color: #929292;
  }
}
@keyframes slideRight {
  0% {
    transform: translateX(100px); /* Initial position */
  }
  100% {
    transform: translateX(0); /* Moves 100px to the right */
  }
}
