.payment-rules-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 1010;
  overflow-y: auto;
  padding: 3.3%;
}
.payment-rules-modal-content {
  background: white;
  border-radius: 0.25rem;
  width: 530px;
  animation: zoomIn 0.3s ease-in-out;
  margin: auto;
  .error {
    color: red;
    font-family: "Inter";
    font-size: 0.7rem;
  }
  .alert-warning {
    width: 95%;
    margin: auto;
    border: 1px solid #f7e1a1;
    background-color: #fdf8e8;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: start;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    .left-content {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #5c5f62;
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem;
        width: 1rem;
        background-color: #deaf21;
        border-radius: 50%;
        display: flex;
        align-items: start;
        column-gap: 0.5rem;
        justify-content: center;
        .danger {
          height: 0.5rem;
          width: 0.5rem;
        }
        .warning {
          height: 0.85rem;
          width: 0.85rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #5c5f62;
    }
    .close {
      cursor: pointer;
      height: 0.75rem;
      width: 0.75rem;
      filter: brightness(0) saturate(100%) invert(59%) sepia(4%) saturate(5%)
        hue-rotate(356deg) brightness(85%) contrast(82%);
      margin-left: 0.2rem;
    }
  }
  .alert-danger {
    width: 95%;
    margin: auto;
    border: 1px solid #ebadad;
    background-color: #faebeb;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .left-content {
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem !important;
        width: 1rem !important;
        background-color: #cc3333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        img {
          height: 0.5rem;
          width: 0.5rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
  .container {
    .header {
      font-family: Inter;
      font-size: 1rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem;
      border-bottom: 1px solid #ebeaed;

      img {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
      }
    }
    .content {
      width: calc(94% - 2rem);
      padding: 2rem;
      .payment-rules-input {
        margin-bottom: 2rem;
        width: 100%;
        .label-header {
          margin-bottom: 0.5rem;
          color: #444444;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          line-height: 1.2rem;
          display: flex;
          align-items: center;
          position: relative;
          .plus-icon {
            height: 1.3rem;
            margin-left: 1.5rem;
            cursor: pointer;
          }
          .dropdown-content {
            width: 14rem;
            border-radius: 0.3rem;
            position: absolute;
            background-color: #f9f9f9;
            box-shadow: 0px 2px 6px 0px #0000002d;
            z-index: 1;
            border-radius: 0.25rem;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #19191c;
            left: 8.8rem;
            top: 0.5rem;
            cursor: pointer;
            .item {
              display: flex;
              column-gap: 1rem;
              padding: 0.75rem;
            }
            .item:hover {
              background-color: #eee9fd;
            }
          }
        }
        .rules-sub-text {
          font-family: Inter;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 0.9rem;
          color: #202020;
          margin-top: 2rem;
        }
        .shifts-row {
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;
          padding-top: 1rem;
          .shift {
            font-family: Inter;
            font-size: 0.75rem;
            font-weight: 400;
            padding: 0.8rem 1.75rem;
            border: 1px solid #c5c5c5;
            border-radius: 0.5rem;
            position: relative;
            .close-icon {
              height: 1rem;
              width: 1rem;
              background-color: #e8361e;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              position: absolute;
              right: -0.3rem;
              top: -0.5rem;
              img {
                height: 0.5rem;
                width: 0.5rem;
              }
            }
          }
        }
        .text-area-content {
          width: calc(100% - 2rem);
        }
      }
      .payment-columns-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
        .payment-columns {
          width: 50%;
          height: 6.5rem;
        }
        .payment-columns-right {
          width: 50%;
          height: 6.5rem;
        }
        .payment-columns-toggle {
          width: 50%;
          display: flex;
          align-items: center;
          font-family: Inter;
          font-size: 0.75rem;
          font-weight: 400;
          justify-content: space-between;
          margin-bottom: 2rem;
          .alert {
            cursor: pointer;
            height: 1.125rem;
            width: 1.125rem;
            background-color: #deaf21;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.5rem;
            img {
              height: 0.8rem;
              width: 0.8rem;
            }
          }
        }
      }
      .payment-rules-btn-container {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }
}
@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
