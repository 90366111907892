.FilterTab {
  height: 3rem;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ebeaed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  .left-container {
    width: 47%;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    .filter-ic {
      margin-right: 0.5rem;
      cursor: pointer;
      img {
        height: 1rem;
        width: 1rem;
      }
    }
    .filter {
      font-family: "Inter";
      font-size: 0.8rem;
      font-weight: 400;
      color: #19191c;
      padding: 0.5rem 1rem;
      border: 1px solid #e4e4e4;
      border-radius: 2.5rem;
      cursor: pointer;
      background-color: #fff;
    }
    .filter-selected {
      font-family: "Inter";
      font-size: 0.8rem;
      font-weight: 400;
      color: #19191c;
      border: 1.5px solid #6f47eb;
      cursor: pointer;
      border-radius: 2.5rem;
      .filter {
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem 1rem;
        border-radius: 2.5rem;
        cursor: pointer;
        background-color: #eee9fd;
        color: #6f47eb;
      }
    }
    .filter-dropdown {
      position: relative;
      display: inline-block;
      .filter-cont {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 400;
        color: #19191c;
        padding: 0.5rem 1rem;
        border: 1px solid #e4e4e4;
        border-radius: 2.5rem;
        cursor: pointer;
        background-color: #fff;
        img {
          height: 0.75rem;
          width: 0.75rem;
        }
      }
      .dropdown-content {
        width: 30%;
        border: 1px solid #e4e4e4;
        padding: 0.6rem 0.875rem;
        border-radius: 0.3rem;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 2px 6px 0px #00000013;
        z-index: 1;
        border-radius: 0.5rem;
        font-family: "Inter";
        font-size: 0.813rem;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: #19191c;
        cursor: pointer;
        margin-top: 0.2rem;
        .item {
          padding: 0.2rem 0.4rem;
        }
        .item:hover {
          background-color: #eee9fd;
        }
      }
    }
  }
  .left-conteinr-floor {
    width: 100%;
    column-gap: 0;
    justify-content: space-evenly;
  }
  .right-container {
    width: 47%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .details-container {
      display: flex;
      align-items: center;
      height: 3rem;
      border-right: 1px solid #ebeaed;
      margin-right: 3rem;
      padding-right: 1.813rem;
      column-gap: 1rem;
      .count {
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 500;
        color: #84818a;
        margin-left: 0.5rem;
      }
      .table {
        display: flex;
        align-items: center;
        .table-res {
          height: 1.625rem;
          width: 1.625rem;
          background: #20c9ac1a;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 1rem;
          }
        }
        .cover {
          height: 1.625rem;
          width: 1.625rem;
          background: #00a5ff1a;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 1rem;
          }
        }
      }
    }
    .more-icon {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      img {
        height: 1rem;
        width: 1rem;
      }
    }
  }
}
.FilterTab-floor {
  height: 3rem;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ebeaed;
  display: flex;
  align-items: center;
  justify-content: center;
  .left-container {
    width: 47%;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    .filter-ic {
      margin-right: 0.5rem;
      cursor: pointer;
      img {
        height: 1rem;
        width: 1rem;
      }
    }
    .filter {
      font-family: "Inter";
      font-size: 0.8rem;
      font-weight: 400;
      color: #19191c;
      padding: 0.5rem 1rem;
      border: 1px solid #e4e4e4;
      border-radius: 2.5rem;
      cursor: pointer;
      background-color: #fff;
    }
    .filter-selected {
      font-family: "Inter";
      font-size: 0.8rem;
      font-weight: 400;
      color: #19191c;
      border: 1.5px solid #6f47eb;
      cursor: pointer;
      border-radius: 2.5rem;
      .filter {
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem 1rem;
        border-radius: 2.5rem;
        cursor: pointer;
        background-color: #eee9fd;
        color: #6f47eb;
      }
    }
    .filter-dropdown {
      position: relative;
      display: inline-block;
      .filter-cont {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        font-family: "Inter";
        font-size: 0.8rem;
        font-weight: 400;
        color: #19191c;
        padding: 0.5rem 1rem;
        border: 1px solid #e4e4e4;
        border-radius: 2.5rem;
        cursor: pointer;
        background-color: #fff;
        img {
          height: 0.75rem;
          width: 0.75rem;
        }
      }
      .dropdown-content {
        width: 30%;
        border: 1px solid #e4e4e4;
        padding: 0.6rem 0.875rem;
        border-radius: 0.3rem;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 2px 6px 0px #00000013;
        z-index: 1;
        border-radius: 0.5rem;
        font-family: "Inter";
        font-size: 0.813rem;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: #19191c;
        cursor: pointer;
        margin-top: 0.2rem;
        .item {
          padding: 0.2rem 0.4rem;
        }
        .item:hover {
          background-color: #eee9fd;
        }
      }
    }
  }
  .left-conteinr-floor {
    width: 100%;
    column-gap: 0;
    justify-content: space-evenly;
  }
  .right-container {
    width: 47%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .details-container {
      display: flex;
      align-items: center;
      height: 3rem;
      border-right: 1px solid #ebeaed;
      margin-right: 3rem;
      padding-right: 1.813rem;
      column-gap: 1rem;
      .count {
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 500;
        color: #84818a;
        margin-left: 0.5rem;
      }
      .table {
        display: flex;
        align-items: center;
        .table-res {
          height: 1.625rem;
          width: 1.625rem;
          background: #20c9ac1a;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 1rem;
          }
        }
        .cover {
          height: 1.625rem;
          width: 1.625rem;
          background: #00a5ff1a;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 1rem;
          }
        }
      }
    }
    .more-icon {
      height: 1rem;
      width: 1rem;
      cursor: pointer;
      img {
        height: 1rem;
        width: 1rem;
      }
    }
  }
}
