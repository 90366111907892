.CheckBox {
  font-family: "Inter";
  font-size: 0.875rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  column-gap: 12px;
  .check {
    height: 1rem;
    width: 1rem;
    border: 1px solid #cccccc;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .checked {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1rem;
      width: 1rem;
      background: #6f47eb;

      img {
        height: 0.7rem;
        width: 0.7rem;
        filter: brightness(0) saturate(100%) invert(100%) sepia(8%)
          saturate(29%) hue-rotate(228deg) brightness(107%) contrast(99%);
        margin: 0;
      }
    }
    img {
      height: 1rem;
      width: 1rem;
    }
  }
}
