.ShiftTimeWindowContainer {
  width: 100%;

  .ShiftTimeWindow {
    display: flex;
    column-gap: 1.25rem;
    margin-top: 1.25rem;

    .window-container {
      border: 1px solid #ededed;
      border-radius: 0.5rem;
      width: 100%;
      padding: 1.375rem;
      .error {
        color: red;
        font-family: "Inter";
        font-size: 0.7rem;
        margin-top: 0.5rem;
      }
      .window-top {
        display: flex;
        width: 100%;
        column-gap: 0.875rem;
        .delete-cont {
          display: flex;
          align-items: center;
          .delete {
            margin-top: 1rem;
            height: 1rem;
            cursor: pointer;
          }
        }
        .start {
          width: 35%;
          .header {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;
            color: #202020;
          }
          select {
            margin-top: 0.5rem;
            width: 100%;
            padding: 0.8rem 1rem;
            font-size: 1rem;
            border-radius: 0.25rem;
            border: 1px solid #e8e8e8;
            outline: none;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #202020;
            -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
            -moz-appearance: none; /* Remove default arrow in Firefox */
            appearance: none; /* Remove default arrow in other browsers */
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="gray"/></svg>');
            background-repeat: no-repeat;
            background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
            background-size: 10px auto, 100%;
          }
        }
        .end {
          width: 25%;
          .percent-cnt {
            height: inherit;
            margin-top: 24px;
            border: 1px solid #e8e8e8;
            padding: 0.7rem 0.5rem;
            border-radius: 0.25rem;
            display: flex;
            justify-content: flex-end;
            .percent-int {
              width: 1.5rem;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              color: #202020;
              border: none;
              outline: none;
            }
          }
          .percent-cnt-error {
            height: inherit;
            margin-top: 24px;
            border: 1px solid #ff4848;
            padding: 0.7rem 0.5rem;
            border-radius: 0.25rem;
            display: flex;
            justify-content: flex-end;
            background-color: #fdfbfb;
            .percent-int {
              width: 1.5rem;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              color: #202020;
              border: none;
              outline: none;
              background-color: #fdfbfb;
            }
          }
        }
      }
      .window-bottom {
        margin-top: 1.125rem;
        display: flex;
        column-gap: 1rem;
        .header {
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 16.94px;
          text-align: left;
          color: #202020;
        }
        .radio-group {
          display: flex;
          column-gap: 1rem;
          .radio-container {
            display: flex;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #202020;
            align-items: center;
            column-gap: 0.5rem;
            .radio-btn {
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
              border: 2px solid #a7a9b7;
              cursor: pointer;
            }
            .selected-radio-btn {
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
              cursor: pointer;
              background-color: #6f47eb;
              border: 2px solid #6f47eb;
              display: flex;
              align-items: center;
              justify-content: center;
              .selected-inner {
                height: 0.4rem;
                width: 0.4rem;
                border-radius: 50%;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
    .option-container {
      display: flex;
      flex-direction: column;
      justify-content: end;
      row-gap: 2.5rem;
      .delete-action {
        height: 1.125rem;
        cursor: pointer;
        img {
          height: 1.125rem;
          filter: brightness(0) saturate(100%) invert(36%) sepia(7%)
            saturate(232%) hue-rotate(169deg) brightness(98%) contrast(88%);
        }
      }
      .add-action {
        height: 1.125rem;
        cursor: pointer;
        img {
          height: 1.125rem;
        }
      }
    }
  }
  .error-message {
    color: red;
    font-family: "Inter";
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
}
