@import "../../colors/colors.scss";
.page {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: $primary-blue;
  position: relative;
  .border {
    width: 100%;
    height: 0.3rem;
    background-color: $secondary-brown;
  }
  .loader {
    border: 0.15rem solid $secondary-brown;
    border-top: 0.15rem solid #ffffff52;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 20%;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .BookingPageOne {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
    .left-container {
      height: auto;
      width: 45%;
      display: flex;
      .logo-witmeg {
        height: 5.1rem;
        width: 7.1rem;
        margin: auto;
        margin-top: 2rem;
        margin-left: -6%;
        img {
          height: 5.1rem;
          width: 7.1rem;
        }
      }
    }
    .right-container {
      margin-top: 5%;
      height: max-content;
      width: 45%;
      .form-container {
        height: 90%;
        width: 100%;
        background-color: $white;
        border-radius: 0.25rem;
        padding: 0.75rem;
        overflow-y: auto;
        label {
          margin-bottom: 0.5rem;
          color: #444444;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          line-height: 1.2rem;
          margin-left: 0.5rem;
        }
        .time-label {
          margin-top: 1.5rem;
        }
        .alert-warning {
          margin: auto;
          border: 1px solid #f7e1a1;
          background-color: #fdf8e8;
          padding: 0.75rem;
          border-radius: 0.25rem;
          display: flex;
          align-items: center;
          margin-top: 1rem;
          column-gap: 1rem;
          .left-content {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #1a1a1a;
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            .cancel {
              height: 1rem;
              width: 1rem;
              background-color: #deaf21;
              border-radius: 50%;
              display: flex;
              align-items: center;
              column-gap: 0.5rem;
              justify-content: center;
              .danger {
                height: 0.5rem;
                width: 0.5rem;
              }
              .warning {
                height: 0.85rem;
                width: 0.85rem;
              }
            }
          }
          .right-content {
            width: 90%;
            display: flex;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #1a1a1a;
          }
        }
        .time-window-des {
          display: flex;
          margin: auto;
          column-gap: 1.5rem;
          width: max-content;
          margin-top: 2rem;
          .des {
            display: flex;
            column-gap: 1rem;
            font-family: "Inter";
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 14.52px;
            text-align: left;
            align-items: center;
            .box {
              height: 2.125rem;
              width: 2.125rem;
              border-radius: 0.25rem;
              background-color: #5c5f62;
              display: flex;
              align-items: center;
              justify-content: center;
              .unavailable {
                height: 1.25rem;
                width: 1.25rem;
                border-radius: 50%;
                background-color: #d9d9d9;
              }
              .busy {
                height: 1.25rem;
                width: 1.25rem;
                border-radius: 50%;
                background-color: #f6dd9e;
              }
              .available {
                height: 1.25rem;
                width: 1.25rem;
                border-radius: 50%;
                background-color: #ffffff;
              }
            }
          }
        }
        .closed {
          margin-top: 1.5rem;
          height: 35vh;
          background-color: #eeeeee;
          display: flex;
          align-items: center;
          justify-content: center;
          .header {
            font-family: "Plus Jakarta Sans";
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0.005em;
            text-align: center;
            color: #d76d77;
          }
          .sub {
            font-family: Plus Jakarta Sans;
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 0.005em;
            text-align: center;
            color: #212121;
            margin-top: 0.75rem;
          }
        }

        .guest-and-date-selector {
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;
          width: 100%;
          margin-top: 2rem;
          .left {
            width: 33%;
            .dropdown {
              display: flex;
              flex-direction: column;
              width: 100%;

              select {
                padding: 1rem 1rem;
                font-size: 1rem;
                border-radius: 0.25rem;
                border: 1px solid $border-grey;
                outline: none;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 1.2rem;
                color: #202020;
                -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
                -moz-appearance: none; /* Remove default arrow in Firefox */
                appearance: none; /* Remove default arrow in other browsers */
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="black"/></svg>');
                background-repeat: no-repeat;
                background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
                background-size: 10px auto, 100%;
              }
            }
          }
          .right {
            width: 67%;
            display: flex;
            gap: 1rem;
            .dropdown {
              display: flex;
              flex-direction: column;
              width: 50%;

              select {
                padding: 1rem 1rem;
                font-size: 1rem;
                border-radius: 0.25rem;
                border: 1px solid $border-grey;
                outline: none;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 1.2rem;
                color: #202020;
                -webkit-appearance: none; /* Remove default arrow in Chrome and Safari */
                -moz-appearance: none; /* Remove default arrow in Firefox */
                appearance: none; /* Remove default arrow in other browsers */
                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5"><path d="M0 0l5 5 5-5H0z" fill="black"/></svg>');
                background-repeat: no-repeat;
                background-position: right 0.7rem top 50%, 0 0; /* Position the arrow icon */
                background-size: 10px auto, 100%;
              }
            }
          }
        }
      }
      .button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;
      }
    }
  }
  .BookingPageThree {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 10%;
    padding-bottom: 2%;
    .left-container {
      height: auto;
      width: 30%;
      display: flex;
      .logo-witmeg {
        height: 5.1rem;
        width: 7.1rem;
        margin: auto;
        margin-top: 2rem;
        margin-left: -16%;
        img {
          height: 5.1rem;
          width: 7.1rem;
        }
      }
    }
    .right-container {
      margin-top: 5%;
      height: max-content;
      width: 35%;
      .form-container {
        height: 95%;
        width: 100%;
        background-color: $white;
        border-radius: 0.25rem;
        padding: 0.5rem;
        text-align: center;
        padding-bottom: 6%;
        .check-container {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 3rem;
          margin-bottom: 5rem;
          .check-icon-container {
            height: 5rem;
            width: 5rem;
            background: #6e6e9f33;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .check {
              height: 2rem;
            }
          }
        }
        .info {
          .header {
            font-family: "Inter";
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.8rem;
            text-align: center;
          }
          .info-content {
            font-family: Urbanist;
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 1.3rem;
            letter-spacing: 0.20000000298023224px;
            span {
              background: -webkit-linear-gradient(#9696be, #2d2d55);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
.page2 {
  width: 100%;
  background-color: $primary-blue;
  min-height: 100vh;
  height: 100%;
  .payment-loader {
    position: fixed;
    z-index: 10;
    background-color: #292929ab;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: "Inter";
    color: #fff;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-icon {
      border: 0.1rem solid $white;
      border-top: 0.15rem solid transparent;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      animation: spin 0.7s linear infinite;
      margin-right: 1rem;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .border {
    width: 100%;
    height: 0.3rem;
    background-color: $secondary-brown;
  }
  .loader {
    border: 0.15rem solid $secondary-brown;
    border-top: 0.15rem solid #ffffff52;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 20%;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .BookingPageTwo {
    height: 99%;
    width: 100%;
    display: flex;
    justify-content: center;
    .left-container {
      height: 90%;
      width: 35%;
      display: flex;
      .logo-witmeg {
        height: 5.1rem;
        width: 7.1rem;
        margin: auto;
        margin-top: 2rem;
        margin-left: -6%;
        img {
          height: 5.1rem;
          width: 7.1rem;
        }
      }
    }
    .right-container {
      margin-top: 2%;
      margin-bottom: 2%;
      width: 55%;
      .form-container {
        height: 100%;
        width: 100%;
        background-color: $white;
        border-radius: 0.25rem;
        padding: 0.5rem;

        .form {
          border-top: 1px solid $border-grey;
          margin-top: 0.5rem;
          .error {
            color: red;
            font-family: "Inter";
            font-size: 0.7rem;
          }
          .header {
            margin-top: 1rem;
            margin-left: 1rem;
            color: #000;
            font-family: "Inter";
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.2rem;
          }
          .terms-list {
            font-size: 0.85rem;
            font-family: "Inter";
            line-height: 1.2rem;
            color: #585858;
          }
          .payment-container {
            padding: 2rem;
            display: flex;
            gap: 4rem;
            .header-payment {
              margin-top: 1rem;
              color: #000;
              font-family: "Inter";
              font-size: 1rem;
              font-weight: 600;
              line-height: 1.2rem;
              margin-bottom: 2rem;
            }
            .payment-container-left {
              width: 50%;
              .back-btn-cont {
                display: none;
              }
            }
            .payment-container-right {
              width: 40%;
              .booking-det {
                padding-bottom: 0.625rem;
                border-bottom: 1px solid $border-grey;
                .booking-det-item {
                  display: flex;
                  color: #000;
                  font-family: "Inter";
                  font-size: 0.875rem;
                  color: #414141;
                  margin-bottom: 0.75rem;
                  .item-head {
                    color: #000;
                    font-family: "Inter";
                    font-size: 0.875rem;
                    font-weight: 600;
                    width: 50%;
                  }
                }
              }
              .booking-det-total {
                margin-top: 1rem;
                .booking-det-total-item {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  color: #000;
                  font-family: "Inter";
                  font-size: 0.875rem;
                  color: #414141;
                  margin-bottom: 0.75rem;
                  .item-head {
                    color: #000;
                    font-family: "Inter";
                    font-size: 0.875rem;
                    width: 80%;
                  }
                }
                .booking-det-total-item-head {
                  color: #000;
                  font-family: "Inter";
                  font-size: 0.95rem !important;
                  font-weight: 600;
                  .item-head {
                    color: #000;
                    font-family: "Inter";
                    font-size: 0.95rem !important;
                    font-weight: 600;
                    width: 80%;
                  }
                }
                .booking-det-total-item-sub {
                  color: #7a7a7a !important;
                  font-family: "Inter";
                  font-size: 0.75rem !important;
                  .item-head {
                    color: #7a7a7a !important;
                    font-family: "Inter";
                    font-size: 0.75rem !important;
                    width: 80%;
                  }
                }
              }
            }
          }
          .form-cont {
            padding: 2rem;
            margin-bottom: -2rem;
            .alert-container {
              background-color: #9696be42;
              border: 1px solid $border-grey;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              padding: 1rem;

              .image {
                img {
                  height: 1.8rem;
                }
              }
              .content {
                margin-left: 1rem;
                .content-header {
                  color: #414141;
                  font-family: "Inter";
                  font-size: 0.9rem;
                  font-weight: 600;
                }
                .content-sub {
                  margin-top: 0.25rem;
                  color: #505050;
                  font-family: "Inter";
                  font-size: 0.8rem;
                  font-weight: 400;
                }
              }
            }
            .rules-container {
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              margin-bottom: 3rem;

              .image {
                img {
                  height: 1.8rem;
                }
              }
              .content {
                margin-left: 1rem;
                .content-header {
                  color: #414141;
                  font-family: "Inter";
                  font-size: 0.9rem;
                  font-weight: 600;
                }
                .content-sub {
                  margin-top: 0.25rem;
                  color: #505050;
                  font-family: "Inter";
                  font-size: 0.8rem;
                  font-weight: 400;
                }
              }
            }
            .rest-details {
              display: flex;
              margin-top: 2rem;
              column-gap: 10%;
              margin-bottom: 2rem;

              .det-cont {
                display: flex;

                .image {
                  img {
                    height: 1.6rem;
                  }
                }
                .content {
                  margin-left: 1rem;
                  .content-header {
                    color: #414141;
                    font-family: "Inter";
                    font-size: 0.9rem;
                    font-weight: 600;
                  }
                  .content-sub {
                    margin-top: 0.25rem;
                    color: #505050;
                    font-family: "Inter";
                    font-size: 0.8rem;
                    font-weight: 400;
                  }
                }
              }
            }

            .name-container {
              display: flex;
              column-gap: 3rem;
              margin-bottom: 1rem;
              .input-flex {
                width: 45%;
              }
            }
            .guest-container {
              width: 96%;
              margin-bottom: 1rem;
            }
            .policy-content {
              margin-top: 1rem;
              color: $light-grey;
              font-family: "Inter";
              font-size: 0.7rem;
              font-weight: 400;
              line-height: 1.2rem;
              display: flex;
              align-items: center;
              margin-bottom: 2rem;
              .unchecked {
                height: 1rem;
                width: 1rem;
                border: 1px solid $drak-grey;
                margin-right: 1rem;
                border-radius: 0.2rem;
                cursor: pointer;
              }
              .checked {
                height: 1rem;
                width: 1rem;
                border: 1px solid #2d2d55;
                margin-right: 1rem;
                border-radius: 0.2rem;
                cursor: pointer;
                background-color: #9696be;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  height: 0.75rem;
                  width: 0.75rem;
                }
              }
              span {
                background: -webkit-linear-gradient(#9696be, #2d2d55);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                margin-left: 0.2rem;
                margin-right: 0.2rem;
              }
            }
          }
        }
      }
      .button-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 1.5rem;
        column-gap: 1rem;
        .back-btn {
          padding: 0.75rem 3rem;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1rem;
          cursor: pointer;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .page {
    .BookingPageOne {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 94%;
        margin-top: 10%;
        padding-bottom: 10%;
        .form-container {
          margin: auto;
          .guest-and-date-selector {
            display: flexbox;
            flex-direction: column-reverse;
            .left {
              width: calc(50% - 10px);
            }
            .right {
              width: 100%;
            }
          }
        }
      }
    }
    .BookingPageThree {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 96%;
        margin-top: 10%;
        padding-bottom: 10%;
        .form-container {
          margin: auto;
          padding-bottom: 10%;
        }
      }
    }
  }
  .page2 {
    .BookingPageTwo {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 96%;
        margin-top: 10%;
        padding-bottom: 10%;
        .form-container {
          .form {
            margin: auto;
            .payment-container {
              flex-direction: column-reverse;
              .payment-container-left {
                width: 100%;
                .back-btn-cont {
                  display: block;
                  margin-top: -5rem;
                }
              }
              .payment-container-right {
                width: 100%;
                .back-btn-cont {
                  display: none;
                }
              }
            }
            .header {
              margin-top: 3rem;
            }
            .form-cont {
              padding: 1rem;
              .name-container {
                display: block;
                .input-flex {
                  width: 91%;
                  margin-bottom: 1rem;
                }
              }
              .guest-container {
                width: 91%;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-device-width: 600px) and (max-width: 900px) {
  .page {
    .BookingPageOne {
      display: block;
      .left-container {
        width: 100%;
        height: auto;
      }
      .right-container {
        margin-top: 0;
        width: 97%;
        height: auto;
        padding-bottom: 10%;
        .form-container {
          margin: auto;
          min-height: 51.1vh;
        }
      }
    }
    .BookingPageThree {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 98%;
        padding-bottom: 10%;
        .form-container {
          margin: auto;
          padding-bottom: 10%;
        }
      }
    }
  }
  .page2 {
    .BookingPageTwo {
      display: block;
      .left-container {
        width: 100%;
      }
      .right-container {
        width: 98%;
        padding-bottom: 10%;
        .form-container {
          .form {
            margin: auto;
            .payment-container {
              flex-direction: column-reverse;
              .payment-container-left {
                width: 100%;
                .back-btn-cont {
                  display: block;
                  margin-top: -5rem;
                }
              }
              .payment-container-right {
                width: 100%;
                .back-btn-cont {
                  display: none;
                }
              }
            }
            .form-cont {
              .name-container {
                display: block;
                .input-flex {
                  width: 95%;
                  margin-bottom: 1rem;
                }
              }
              .guest-container {
                width: 95%;
              }
            }
          }
        }
      }
    }
  }
}
.adyen-checkout__checkbox {
  display: none !important;
}
