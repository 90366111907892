.CustomerManagement {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  .landscape {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .empty {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    display: flex;
    .loader {
      border: 0.1rem solid #9d9d9d;
      border-top: 0.15rem solid transparent;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      animation: spin 1s linear infinite;
      margin: auto;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .MuiPhoneNumber-flagButton {
    height: 16px;
    padding: 0;
    min-width: 16px;
  }
  .css-1eed5fa-MuiInputBase-root-MuiInput-root {
    font-family: "Inter";
    font-weight: 400;
    font-size: 0.75rem;
    color: #202020;
  }
  .management-content {
    width: 100%;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    .loader {
      border: 0.1rem solid #9d9d9d;
      border-top: 0.15rem solid transparent;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      animation: spin 1s linear infinite;
      margin: auto;
      margin-top: 10%;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .management-view {
      margin-top: 4.05rem;
      display: flex;
      position: relative;
      overflow: hidden;
      height: 91vh;
      .left-view {
        width: 20%;
        border-right: 1px solid #ededed;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: #fff;
        min-width: 18vw;
        position: relative;
        .search-bar {
          background-color: #fff;
          padding: 1.25rem;
          border-bottom: #ebeaed 1px solid;
          input {
            width: 93%;
          }
        }
        .contacts-container {
          height: 62vh;
          overflow-y: auto;
          margin-bottom: 12vh;
          .list {
            .empty-container {
              width: 100%;
              text-align: center;
              padding-top: 20%;
              padding-bottom: 2%;
              font-family: "Inter";
              font-size: 0.875rem;
              font-weight: 400;
              color: #84818a;
              border-bottom-left-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
            .letter-head {
              padding: 1rem 1.25rem;
              border-bottom: #ebeaed 1px solid;
              .letter {
                height: 2rem;
                width: 2rem;
                background-color: #eee9fd;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                font-family: "Manrope";
                font-size: 1rem;
                font-weight: 700;
                color: #6f47eb;
              }
            }
            .contact-list {
              padding-top: 1rem;
              .empty-customer {
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 400;
                color: #84818a;
                padding: 1rem 1.25rem;
              }
              .contact {
                padding: 1rem 1.25rem;
                font-family: "Inter";
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 16.94px;
                text-align: left;
                color: #202020;
                cursor: pointer;
                text-transform: capitalize;
              }
              .selected {
                color: #6f47eb;
                background-color: #eee9fd52;
              }
            }
          }
        }
        .button-container {
          position: absolute;
          background-color: #fff;
          bottom: 0 !important;
          padding: 1rem;
          width: 89%;
          .btn-container {
            width: 100%;
            border-top: #0000001a 1px solid;
            padding-top: 10px;
            .margin-top {
              margin-top: 10px;
            }
          }
        }
      }
      .right-view {
        width: 81%;
        background-color: #f9f9f9;

        .header {
          width: 100%;
          background-color: #fff;

          border-bottom: #ebeaed 1px solid;
          .head {
            padding: 1.5rem 1rem 0.9rem;
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 600;
            line-height: 20px;
            border-bottom: 2px solid #6f47eb;
            width: max-content;
            margin-left: 1rem;
          }
        }
        .details {
          height: 90%;
          .profile-header {
            width: 90%;
            padding: 1rem;
            background-color: #fff;
            margin: auto;
            margin-top: 2rem;
            box-shadow: 0px 1px 3px 0px #00000014;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            .profile {
              width: 80%;
              display: flex;
              align-items: center;
              column-gap: 1.25rem;
              .avatar {
                height: 3rem;
                width: 3rem;
                border-radius: 50%;
                background-color: #eee9fd;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Manrope";
                font-size: 18px;
                font-weight: 700;
                color: #6f47eb;
                text-transform: uppercase;
              }
              .name-container {
                .name {
                  font-family: Inter;
                  font-size: 1rem;
                  font-weight: 600;
                  line-height: 24px;
                  text-align: left;
                  color: #202020;
                  text-transform: capitalize;
                }
                .email {
                  font-family: Inter;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 16.94px;
                  text-align: left;
                  color: #484848;
                  display: flex;
                  align-items: center;
                  column-gap: 0.5rem;
                  img {
                    height: 11px;
                  }
                }
              }
            }
            .button-cont {
              width: 20%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          .details-conatiner {
            width: 90%;
            padding: 1rem;
            height: 70%;
            background-color: #fff;
            margin: auto;
            margin-top: 2rem;
            box-shadow: 0px 1px 3px 0px #00000014;
            display: flex;
            border-radius: 0.5rem;

            .container {
              width: 50%;

              .header-det {
                font-family: Inter;
                font-size: 1rem;
                font-weight: 600;
                line-height: 20px;
                padding-bottom: 1rem;
              }
              .container-wrapper {
                height: 90%;
                overflow-y: auto;
                width: 90%;
                .details-table-cont {
                  width: 90%;
                  border: 1px solid #0000001a;
                  border-radius: 0.25rem;
                  margin-bottom: 2rem;
                  .details-header {
                    font-family: "Manrope";
                    font-size: 0.875rem;
                    font-weight: 600;
                    padding: 1rem;
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                    border-bottom: 1px solid #ebeaed;
                    display: flex;
                    align-items: center;
                    column-gap: 0.625rem;
                    img {
                      height: 1rem;
                    }
                  }
                  .details-row {
                    padding: 1rem;

                    .row-cont {
                      display: flex;
                      align-items: center;
                      padding-top: 0.5rem;
                      padding-bottom: 0.5rem;
                      .head-text {
                        font-family: "Manrope";
                        font-size: 0.875rem;
                        font-weight: 400;
                        min-width: 120px;
                        color: #2e2c34;
                      }
                      .sub-text {
                        font-family: "Manrope";
                        font-size: 0.875rem;
                        font-weight: 600;
                        min-width: 120px;
                        color: #2e2c34;
                      }
                    }
                  }
                }
                .others-table-cont {
                  width: 90%;
                  border: 1px solid #0000001a;
                  border-radius: 0.25rem;
                  .expand-header {
                    font-family: "Manrope";
                    font-size: 0.875rem;
                    font-weight: 400;
                    padding: 1rem;
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    img {
                      height: 0.87rem;
                      transform: rotate(90deg);
                    }
                  }
                  .line {
                    height: 1px;
                    background-color: #ebeaed;
                    margin-left: 1rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #dfdfdf;
  }
}
@media only screen and (max-width: 1200px) {
  .CustomerManagement {
    height: 100vh;
  }
}
