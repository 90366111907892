.FloorLayout {
  position: relative;
  height: 100%;
  width: 100%;
  .table-bg {
    padding: 0.625rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
  .conat {
    position: absolute;

    .table-layout-bg {
      padding: 0.3em;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border: 1px dashed #c5c5c5;
      background-color: #fff;
    }
    .table-layout-bg-selected {
      background-color: #eee9fd;
      border: 1px dashed #6f47eb;
    }
    .action {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      box-shadow: 0px 2px 4px 0px #00000040;
      border: 0.5px solid #e4e4e4;
      height: 36px;
      border-radius: 0.5rem;
      display: flex;
      width: max-content;
      margin: auto;
      margin-top: -50px;
      z-index: 10000;

      .item {
        width: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          height: 1rem;
          width: 1rem;
        }
      }
      .item:hover {
        background-color: #f9f9f9;
        img {
          filter: brightness(0) saturate(100%) invert(49%) sepia(86%)
            saturate(6786%) hue-rotate(245deg) brightness(96%) contrast(92%);
        }
      }
    }
    .details {
      position: absolute;
      background-color: #ffffff;
      box-shadow: 0px 2px 4px 0px #00000040;
      border: 0.5px solid #e4e4e4;
      width: 380px;
      z-index: 100;
      left: -5% !important;
      top: 4rem;
      .top {
        padding: 20px;
        border-bottom: 1px solid #ebeaed;
        .head {
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 600;
        }
        .name {
          display: flex;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #5c5f62;
          margin-top: 22px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          input {
            width: 124px;
            height: 33px;
            border: 1px solid #0000001a;
            border-radius: 0.25rem;
            outline: none;
            font-family: Inter;
            font-size: 0.875rem;
            font-weight: 400;
            padding-left: 0.5rem;
            color: #5c5f62;
          }
        }
        .online {
          display: flex;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #5c5f62;
          margin-top: 22px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .status {
            display: flex;
            align-items: center;
            margin-right: 2rem;
            column-gap: 0.625rem;
            .active {
              font-family: "Inter";
              font-size: 0.75rem;
              font-weight: 600;
              color: #20c94f;
              width: 4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #20c94f1a;
              border-radius: 0.25rem;
              height: 1.8rem;
            }
            .inactive {
              font-family: "Inter";
              font-size: 0.75rem;
              font-weight: 600;
              color: #e8361e;
              width: 4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #e8361e1a;
              border-radius: 0.25rem;
              height: 1.8rem;
            }
            .switch {
              position: relative;
              display: inline-block;
              width: 44px;
              height: 24px;
              margin-right: -2rem !important;
            }

            .switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }

            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              // transition: 0.4s;
              border-radius: 34px;
            }

            .slider:before {
              position: absolute;
              content: "";
              height: 20px;
              width: 20px;
              left: 2px;
              bottom: 2.05px;
              background-color: white;
              // transition: 0.4s;
              border-radius: 50%;
            }

            input:checked + .slider {
              background-color: #6f47eb;
            }

            input:checked + .slider:before {
              transform: translateX(20px);
            }

            /* Rounded sliders */
            .slider.round {
              border-radius: 34px;
            }

            .slider.round:before {
              border-radius: 50%;
            }
          }
        }
      }
      .bottom {
        padding: 20px;
        .head {
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .check {
            height: 1rem;
            width: 1rem;
            border: 1px solid #cccccc;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .checked {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 1rem;
              width: 1rem;
              background: #6f47eb;

              img {
                height: 0.7rem;
                width: 0.7rem;
                filter: brightness(0) saturate(100%) invert(100%) sepia(8%)
                  saturate(29%) hue-rotate(228deg) brightness(107%)
                  contrast(99%);
                margin: 0;
              }
            }
            img {
              height: 1rem;
              width: 1rem;
            }
          }
        }
        .head-dim {
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 2rem;
          border-top: 1px solid #ebeaed;
          margin-left: -20px;
          margin-right: -20px;
          padding-left: 20px;
          margin-top: 22px;
        }
        .prio {
          display: flex;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
          margin-top: 22px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .number {
            width: 50%;
          }
        }
        .flex-cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #202020;
          margin-top: 22px;
          .left {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            .input-container {
              width: 92px;
              border: 1px solid #0000001a;
              border-radius: 0.25rem;
              height: 37px;
              display: flex;
              align-items: center;
              padding-left: 0.625rem;
              img {
                width: 0.875rem;
              }
              input {
                width: 50px;

                outline: none;
                font-family: Inter;
                font-size: 0.875rem;
                font-weight: 400;
                padding-left: 0.5rem;
                color: #5c5f62;
                border: none;
              }
            }
          }
          .right {
            display: flex;
            column-gap: 1rem;

            align-items: center;
            .input-container {
              width: 92px;
              border: 1px solid #0000001a;
              border-radius: 0.25rem;
              height: 37px;
              display: flex;
              align-items: center;
              padding-left: 0.625rem;
              img {
                width: 0.875rem;
                height: 0.875rem;
              }
              .y {
                transform: rotate(90deg) scaleX(-1);
              }
              input {
                width: 50px;

                outline: none;
                font-family: Inter;
                font-size: 0.875rem;
                font-weight: 400;
                padding-left: 0.5rem;
                color: #5c5f62;
                border: none;
              }
            }
          }
          .angle {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            .input-container {
              width: 92px;
              border: 1px solid #0000001a;
              border-radius: 0.25rem;
              height: 37px;
              display: flex;
              align-items: center;
              padding-left: 0.625rem;
              img {
                width: 0.875rem;
              }
              input {
                width: 50px;

                outline: none;
                font-family: Inter;
                font-size: 0.875rem;
                font-weight: 400;
                padding-left: 0.5rem;
                color: #5c5f62;
                border: none;
              }
            }
          }
        }
        .error-cont {
          font-family: "Inter";
          font-size: 0.875rem;
          font-weight: 400;
          color: #cc3333;
          margin-top: 1.5rem;
          .error {
            margin-top: 0.625rem;
            display: flex;
            column-gap: 0.5rem;
            img {
              height: 1rem;
              width: 1rem;
            }
          }
        }
        .btn-container {
          margin-top: 1.8rem;
        }
      }
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}
.FloorLayout-empty {
  border: 1px solid #6f47eb;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  color: #6f47eb;
  position: relative;
  height: 100%;
  width: 100%;
}
.FloorLayout-blank {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty-floor {
    border: 1px solid #c5c5c5;
    border-radius: 0.25rem;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    width: 14rem;
    padding: 3rem 4rem;
    .text {
      margin-bottom: 1rem;
    }
  }
}
