.DatePicker {
  display: flex;
  align-items: center;
  .backarrow {
    cursor: pointer;
    img {
      height: 0.813rem;
      transform: rotate(-90deg);
    }
  }
  .rightarrow {
    cursor: pointer;
    margin-left: -1rem;
    img {
      height: 0.813rem;
      transform: rotate(90deg);
    }
  }
  .date {
    display: flex;
    overflow-x: auto;
    padding: 0.625rem;
    margin: 0.313rem;
    font-family: "Inter";
    font-size: 0.9rem;
    font-weight: 500;
    align-items: center;
    column-gap: 0.75rem;
    img {
      height: 0.9rem;
    }
  }
  .react-datepicker__input-container input {
    font-family: "Inter";
    font-size: 0.9rem;
    font-weight: 500;
    border: none;
    outline: none;
    width: 6rem;
    text-align: center;
    display: none;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    input {
      font-family: "Inter";
      font-size: 0.9rem;
      font-weight: 500;
      border: none;
      outline: none;
      width: 6rem;
      text-align: center;
    }
  }
  .react-datepicker {
    border: 1px solid #0000000d !important;
    box-shadow: 0px 3px 6px 0px #17254c1f;
  }
  .react-datepicker__header {
    text-align: center;
    background-color: #fff !important;
    border: none !important;
  }
  .react-datepicker__day-name {
    color: #7b8199 !important;
    font-size: 1rem !important;
  }
  .react-datepicker__day-names {
    white-space: nowrap;
    margin-bottom: -8px;
    margin-top: 1rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-family: "Inter";
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border: none !important;
    background: linear-gradient(50.47deg, #6f47eb 0%, #6f47eb 83.75%);
  }
}
