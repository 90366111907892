.qr-container {
  height: auto;
  margin: 3rem auto;
  max-width: 6rem;
  width: "100%";
  .qr {
    height: auto;
    max-width: 6rem;
    width: 6rem;
  }
}
