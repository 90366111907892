.Drawer {
  width: 16.2rem;
  height: 100vh;
  background-color: #fff;
  border-right: 1px solid #ededed;
  position: fixed;

  .homeIC {
    height: 1.75rem;
    width: max-content;
    padding-top: 1.125rem;
    padding: 0.5rem 1.25rem;
    margin: 1rem;
    display: flex;
    align-items: center;
    font-family: "Leckerli One";
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 26.63px;
    letter-spacing: 0.01em;
    text-align: left;
    column-gap: 0.688rem;
    margin-bottom: 2.125rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        height: 1.5rem;
        width: 1.5rem;
      }
      margin-left: 1.2rem;
      transform: rotate(180deg);
    }
    .home-cont {
      display: flex;
      align-items: center;
      justify-content: center;
      .home {
        height: 2.313rem;
        width: 2.313rem;
        margin-right: 0.5rem;
      }
    }
  }
  .add-cont {
    background: #6f47eb;

    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    padding: 0.875rem 1.25rem;
    margin: 1rem;
    border-radius: 0.75rem;
    font-family: "Inter";
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #fff;
    column-gap: 0.75rem;
    cursor: pointer;
    .addBtn {
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }

  .list {
    .border {
      border-top: 1px solid #e4e4e4;
      margin-left: 1.125rem;
      margin-right: 1.125rem;
    }
    .iconList {
      display: flex;
      align-items: center;
      padding: 0.8rem 1.25rem;
      margin-bottom: 0.5rem;
      // margin: 1rem;
      border-radius: 0.375rem;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: #808191;
      cursor: pointer;
      border-left: transparent 4px solid;
      justify-content: space-between;

      .right {
        display: flex;
        column-gap: 0.75rem;
        align-items: center;

        img {
          height: 1rem;
          width: 1rem;
        }
      }
      .arr {
        transform: rotate(90deg);
      }
      .arr-rv {
        transform: none;
      }
    }
    .iconListSelected {
      display: flex;
      align-items: center;
      padding: 1.25rem 1.25rem;
      // margin: 1rem;
      margin-bottom: 0.5rem;

      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: -0.01em;
      column-gap: 0.75rem;
      cursor: pointer;
      color: #11142d;
      border-left: #6c5dd3 4px solid;
      justify-content: space-between;
      .right {
        display: flex;
        column-gap: 0.75rem;
        align-items: center;

        img {
          height: 1rem;
          width: 1rem;
        }
      }
      .arr {
        transform: rotate(90deg);
      }
      .arr-rv {
        transform: none;
      }
    }
    .subtype {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      padding: 0rem 3rem;
      // margin: 1rem;
      border-radius: 0.375rem;
      font-family: "Inter";
      font-size: 0.813rem;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: #808191;
      column-gap: 0.75rem;
      cursor: pointer;
      text-transform: capitalize !important;
    }
    .subtype-selected {
      display: flex;
      align-items: center;
      margin-bottom: 1.2rem;
      padding: 0rem 3rem;
      // margin: 1rem;
      border-radius: 0.375rem;
      font-family: "Inter";
      font-size: 0.813rem;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.01em;
      column-gap: 0.75rem;
      cursor: pointer;
      color: #6f47eb;
      text-transform: capitalize !important;
    }
    .subtype:hover {
      color: #6f47eb;
    }
  }
  .expand {
    position: absolute;
    bottom: 3rem;
    right: 1.5rem;
    height: 1.6rem;
    width: 1.6rem;
    background-color: #6f47eb;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    img {
      height: 0.75rem;
      width: 0.75rem;
      transform: rotate(-90deg);
      margin-left: -0.3rem;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
        hue-rotate(285deg) brightness(105%) contrast(102%);
    }
  }
}
