@import "./view/colors/colors.scss";
.App {
  min-height: 100vh;
  width: 100%;
  font-family: "Inter";
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #9e9e9e;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
select {
  background-color: #fff !important;
  -webkit-appearance: none;
}
@media only screen and (max-width: 1400px) {
  .App {
    height: 100%;
    font-size: 90% !important;
    line-height: 90% !important;
    input {
      font-size: 90% !important;
      line-height: 90% !important;
    }
  }
  body {
    font-size: 90% !important;
    line-height: 90% !important;
  }
}
