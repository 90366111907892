.IncreaseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  .butn {
    height: 1.5rem;
    width: 1.5rem;
    background-color: #e4e4e4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      height: 0.6rem;
      width: 0.6rem;
      filter: brightness(0) saturate(100%) invert(8%) sepia(18%) saturate(2763%)
        hue-rotate(200deg) brightness(92%) contrast(98%);
    }
  }
  .butn:hover {
    background-color: #6f47eb;
    img {
      filter: brightness(0) saturate(100%) invert(95%) sepia(94%) saturate(16%)
        hue-rotate(9deg) brightness(106%) contrast(100%);
    }
  }
  .number {
    font-family: "Inter";
    font-size: 0.75rem;
    font-weight: 600;
    color: #000;
    width: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
