.table-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  z-index: 1010;
  overflow-y: auto;
  padding: 4%;
}

.table-modal-content {
  background: white;
  border-radius: 0.5rem;
  width: 615px;
  animation: zoomIn 0.3s ease-in-out;
  margin: auto;
  .error {
    color: red;
    font-family: "Inter";
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
  .alert-danger {
    width: 95%;
    margin: auto;
    border: 1px solid #ebadad;
    background-color: #faebeb;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .left-content {
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem !important;
        width: 1rem !important;
        background-color: #cc3333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        img {
          height: 0.5rem;
          width: 0.5rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
  .container {
    padding: 20px;

    .header {
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 1.5rem;
        width: 1.5rem;
        cursor: pointer;
      }
    }
    .content {
      width: 94%;
      margin-top: 2rem;
      .covers-cont {
        margin-top: 1.5rem;
        .cvr-head {
          color: #444444;
          font-family: "Inter";
          font-size: 0.8rem;
          font-weight: 500;
          margin-left: 0.5rem;
        }
        .min-max {
          display: flex;
          justify-content: space-between;
          margin-left: 0.5rem;
          .min {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #5c5f62;
            display: flex;
            align-items: center;
            column-gap: 3rem;
            .number {
              height: 3rem;
              width: 6.938rem;
              background: #f9f9f9;
              border: 1px solid #e8e8e8;
              border-radius: 0.25rem;
              outline: none;
              padding-left: 1rem;
              input {
                outline: none;
                border: none;
              }
            }
          }
          .max {
            font-family: "Inter";
            font-size: 0.875rem;
            font-weight: 400;
            color: #5c5f62;
            display: flex;
            align-items: center;
            column-gap: 3rem;
            .number {
              height: 3rem;
              width: 6.938rem;
              background: #f9f9f9;
              border: 1px solid #e8e8e8;
              border-radius: 0.25rem;
              outline: none;
              padding-left: 1rem;
              input {
                outline: none;
                border: none;
              }
            }
          }
        }
      }
    }
  }

  .comb {
    padding: 20px;

    border-top: 1px solid #e8e8e8;
    .header {
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
    .btn-cont {
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
    }
    .floor-type {
      height: 1.2rem;
      display: flex;
      align-items: center;
      width: 90%;
      border: 1px solid #e8e8e8;
      padding: 0.938rem 1.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      .left-shift-view {
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 500;
        text-align: left;
        width: 50%;
      }
      .right-shift-view {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .arrow-cont {
          height: 1.5rem;
          width: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 1rem;
          img {
            height: 0.75rem;
            width: 0.75rem;
          }
        }
      }
    }
    .floor-type-selectd {
      background: #00000024;
      .right-shift-view {
        .arrow-cont {
          img {
            transform: rotate(-180deg);
          }
        }
      }
    }
    .tables {
      width: 88%;
      padding: 0rem 2rem;
      .table {
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 400;
        cursor: pointer;
        padding: 0.8rem 1rem;
        margin-bottom: 0.4rem;
        border-radius: 0.25rem;
        color: #202020;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .check {
          height: 1.5rem;
          width: 1.5rem;
          border: 1px solid #0000001a;
          border-radius: 50%;
        }
        .checked {
          height: 1.5rem;
          width: 1.5rem;
          background-color: #1f7b24;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 0.7rem;
            width: 0.7rem;
            filter: brightness(0) saturate(100%) invert(100%) sepia(1%)
              saturate(2381%) hue-rotate(150deg) brightness(103%) contrast(100%);
          }
        }
      }
      .table-selected {
        background-color: #e5f1e5;
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    column-gap: 2rem;
    .close {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 500;
      color: #1a1a1a;
      cursor: pointer;
    }
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
