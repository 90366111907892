.customer-modal-content {
  background: white;
  position: absolute;
  width: 18rem;
  border: 1px solid #ebebeb;
  height: 91vh;
  margin: auto;
  left: 27.7%;
  z-index: 1000;
  top: 0;
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    img {
      height: 1rem;
      width: 1rem;
      filter: brightness(0) saturate(100%) invert(4%) sepia(2%) saturate(4659%)
        hue-rotate(201deg) brightness(99%) contrast(87%);
      cursor: pointer;
    }
  }
  .content {
    background: white;
    height: 84vh;
    overflow-y: auto;
    .empty-customer {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #84818a;
      padding: 1rem 1.25rem;
      text-align: center;
    }
    .customer {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      cursor: pointer;
      border-bottom: 1.5px solid #f3f3f3;
      .name {
        font-family: "Inter";
        font-size: 0.875rem;
        font-weight: 400;
        color: #191d31;
        line-height: 25.2px;
        text-transform: capitalize;
      }
      .email {
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 400;
        color: #7a7d80;
        line-height: 21.6px;
      }
    }
    .customer:hover {
      background-color: #eee9fd;
    }
  }
}
