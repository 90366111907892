.ShiftsView {
  box-shadow: 0px 1px 3px 0px #00000014;
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;

  justify-content: space-between;
  margin-bottom: 1.5rem;
  height: auto;
  .shift-type {
    height: 1.5rem;
    display: flex;
    align-items: center;
    width: 96.5%;
    border-bottom: 2px solid #f0f0f0;
    padding: 0.938rem 1.5rem;
    cursor: pointer;
    .left-shift-view {
      font-family: "Inter";
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
      color: #202020;
      width: 50%;
    }
    .right-shift-view {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .no-res {
        background: #00a5ff1a;
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 600;
        color: #00a5ff;
        padding: 0.219rem 0.375rem;
        border-radius: 0.25rem;
      }
      .res-det {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        .table {
          font-family: "Inter";
          font-size: 0.813rem;
          font-weight: 500;
          color: #84818a;
          display: flex;
          align-items: center;
          column-gap: 0.4rem;
          .tbl {
            height: 2rem;
            width: 2rem;
            background: #20c9ac1a;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 1.188rem;
            }
          }
        }
        .cover {
          font-family: "Inter";
          font-size: 0.813rem;
          font-weight: 500;
          color: #84818a;
          display: flex;
          align-items: center;
          column-gap: 0.4rem;
          .cvr {
            height: 2rem;
            width: 2rem;
            background: #00a5ff1a;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 1.188rem;
            }
          }
        }
      }
      .arrow-cont {
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        img {
          height: 0.75rem;
          transform: rotate(180deg);
        }
      }
    }
  }
  .shift-table {
    padding: 0.8rem 1.5rem;

    #bookings {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      text-align: left;
      color: #202020;
      width: 100%;

      .arrow {
        height: 0.75rem;
        width: 0.75rem;
        margin-top: 0.5rem;
        cursor: pointer;
      }
      .user {
        height: 0.8rem;
        width: 0.8rem;
        margin-right: 0.3rem;
      }
      .time {
        font-family: "Manrope";
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        background: #20c9ac;
        color: #fff;
        padding: 0.2rem 0.3rem;
        border-radius: 0.2rem;
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .status {
        font-family: "Manrope";
        font-size: 0.75rem;
        font-weight: 600;
        color: #20c94f;
        background: #20c94f1a;
        padding: 0.25rem 0.5rem;
        width: max-content;
        border-radius: 0.2rem;
        text-transform: capitalize;
      }
      .seated {
        font-family: "Manrope";
        font-size: 0.75rem;
        font-weight: 600;
        color: #ebb41d;
        background: #ebb41d1a;
        padding: 0.25rem 0.5rem;
        width: max-content;
        border-radius: 0.2rem;
        text-transform: capitalize;
      }
      .late {
        font-family: "Manrope";
        font-size: 0.75rem;
        font-weight: 600;
        color: #f3428c;
        background: #f3428c1a;
        padding: 0.25rem 0.5rem;
        width: max-content;
        border-radius: 0.2rem;
        text-transform: capitalize;
      }
      .no_answer {
        font-family: "Manrope";
        font-size: 0.75rem;
        font-weight: 600;
        color: #e8361e;
        background: #e8361e1a;
        padding: 0.25rem 0.5rem;
        width: max-content;
        border-radius: 0.2rem;
        text-transform: capitalize;
      }
      .tables {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 0.2rem;
        .table {
          font-family: "Poppins";
          font-size: 10px;
          font-weight: 500;
          line-height: 15px;
          color: #202020;
          border: 1px solid #e4e4e4;
          background-color: #f6f6f6;
          width: max-content;
          padding: 0.25rem 0.8rem;
          border-radius: 1rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
      }
    }

    #bookings td,
    #bookings th {
      padding: 1.125rem;
      border: none;
    }

    #bookings tr:nth-child(even) {
      background-color: #f6f6f6;
    }

    #bookings th {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      font-family: "Inter";
      font-size: 0.75rem;
      font-weight: 400;
      text-align: left;
      color: #84818a;
    }
    #bookings th:first-child {
      max-width: 3rem;
      text-align: center;
    }
    #bookings td:first-child {
      max-width: 3rem;
    }
    #bookings th:nth-child(2) {
      max-width: 5rem;
    }
    #bookings td:nth-child(2) {
      max-width: 5rem;
    }
    #bookings th:nth-child(3) {
      max-width: 4rem;
    }
    #bookings td:nth-child(3) {
      max-width: 4rem;
    }
    #bookings th:nth-child(4) {
      max-width: 7.7rem;
    }
    #bookings td:nth-child(4) {
      max-width: 7.7rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    #bookings th:nth-child(5) {
      max-width: 3rem;
      text-align: center;
    }
    #bookings td:nth-child(5) {
      max-width: 3rem;
    }
    #bookings th:nth-child(6) {
      max-width: 12rem;
      text-align: center;
    }
    #bookings td:nth-child(6) {
      max-width: 12rem;
      word-wrap: break-word;
    }
    #bookings th:nth-child(7) {
      max-width: 3rem;
      text-align: center;
    }
    #bookings td:nth-child(7) {
      max-width: 3rem;
    }
    #bookings td:nth-child(5) {
      color: #84818a;
    }
    #bookings th:nth-child(8) {
      max-width: 3rem;
    }
    #bookings td:nth-child(8) {
      max-width: 3rem;
    }
  }
  .empty-container {
    width: 100%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
    font-family: "Inter";
    font-size: 0.875rem;
    font-weight: 400;
    color: #84818a;
    background-color: #f9f9f9;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
.ShiftsFloorView {
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: auto;
  border: 1px solid #0000001a;
  border-radius: 0.5rem;
  .shift-type {
    height: 1.5rem;
    display: flex;
    align-items: center;
    padding: 0.938rem 1.5rem;
    cursor: pointer;
    justify-content: space-between;

    .left-shift-view {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
      color: #202020;
    }
    .right-shift-view {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .no-res {
        background: #00a5ff1a;
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 600;
        color: #00a5ff;
        padding: 0.219rem 0.375rem;
        border-radius: 0.25rem;
      }
      .res-det {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        .table {
          font-family: "Inter";
          font-size: 0.813rem;
          font-weight: 500;
          color: #84818a;
          display: flex;
          align-items: center;
          column-gap: 0.4rem;
          .tbl {
            height: 1.5rem;
            width: 1.5rem;
            background: #20c9ac1a;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 1.1rem;
            }
          }
        }
        .cover {
          font-family: "Inter";
          font-size: 0.813rem;
          font-weight: 500;
          color: #84818a;
          display: flex;
          align-items: center;
          column-gap: 0.4rem;
          .cvr {
            height: 1.5rem;
            width: 1.5rem;
            background: #00a5ff1a;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 1.188rem;
            }
          }
        }
      }
      .arrow-cont {
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        img {
          height: 0.75rem;
          transform: rotate(180deg);
        }
      }
    }
  }
  .shift-list {
    border-top: solid 1px #0000001a;
    padding: 0.8rem 1.5rem;
    background-color: #f9f9f9;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    .shift-container {
      background-color: #fff;
      height: max-content;
      border: 2px solid #0000001a;
      border-radius: 0.25rem;
      padding: 0.75rem;
      cursor: pointer;
      margin-bottom: 1rem;

      .namecontainer {
        display: flex;
        justify-content: space-between;
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 600;
        color: #2e2c34;
        img {
          height: 0.875rem;
          width: 0.875rem;
        }
      }
      .detailscontainer {
        display: flex;
        margin-top: 0.875rem;
        align-items: center;
        .time {
          font-family: "Manrope";
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          background: #20c9ac;
          color: #fff;
          width: max-content;
          padding: 0.2rem 0.3rem;
          border-radius: 0.2rem;
        }
        .det {
          display: flex;
          align-items: center;
          margin-left: 0.8rem;
          font-family: "Manrope";
          font-size: 0.75rem;
          font-weight: 500;
          color: #84818a;
          column-gap: 0.8rem;
          .cont {
            display: flex;
            align-items: center;
            column-gap: 0.2rem;

            img {
              height: 1.1rem;
              width: 1.1rem;
            }
            .tbl {
              height: 0.9rem;
              width: 1.2rem;
            }
          }
        }
      }
    }
  }
  .empty-container {
    width: 100%;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
    font-family: "Inter";
    font-size: 0.875rem;
    font-weight: 400;
    color: #84818a;
    border-top: 1px solid #0000001a;
    background-color: #f9f9f9;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
