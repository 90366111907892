.Table {
  cursor: pointer;
  .chair {
    height: 0.5rem;
    width: 1.875rem;
    border: 1px solid #0000002e;
    border-radius: 1.063;
    background-color: #fff;
    border-radius: 0.5rem;
  }
  .top {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    height: 11px;
    .chair-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    .chair-cont {
      transform: rotate(90deg);
      margin: -6px;
    }
    .table-cont {
      background-color: #fff;
      border: 1px solid #0000002e;
      height: 100%;
      width: 100%;
      border-radius: 0.588rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .inner-cont {
        background-color: #eee9fd;
        height: 60%;
        width: 60%;
        border-radius: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          height: 1.8rem;
          width: 1.8rem;
        }
        .img-status {
          object-fit: contain;
          height: 1.2rem;
          width: 1.2rem;
          filter: brightness(0) saturate(100%) invert(90%) sepia(31%)
            saturate(0%) hue-rotate(90deg) brightness(113%) contrast(101%);
        }
        .gradient-text {
          font-family: "Inter";
          font-size: 0.75rem;
          font-weight: 600;
          color: #6f47eb;
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    .chair-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .table-name {
    display: flex;
    justify-content: center;
    background-color: #140936;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-family: "Poppins";
    font-size: 0.75rem;
    font-weight: 600;
    width: max-content;
    margin: auto;
    margin-top: 0.15rem;
  }
  .table-count {
    display: flex;
    justify-content: center;
    background-color: #20c9ac;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-family: "Poppins";
    font-size: 0.75rem;
    font-weight: 600;
    width: max-content;
    margin: auto;
    margin-top: -1.5rem;
    margin-bottom: 0.3rem;
    align-items: center;
    column-gap: 0.25rem;
    img {
      height: 0.563rem;
    }
  }
  .covers-count {
    display: flex;
    justify-content: center;
    background: #7047ebbb;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-family: "Poppins";
    font-size: 0.75rem;
    font-weight: 600;
    width: max-content;
    margin: auto;
    margin-top: -1.5rem;
    margin-bottom: 0.3rem;
    align-items: center;
    column-gap: 0.25rem;
  }
}
.Table-selected {
  background-color: #eee9fd;

  border: 0.95px dashed #6f47eb;
  border-radius: 0.5rem;
}
.Table-layout {
  cursor: pointer;
  padding: 5px;
  position: relative;
  .chair {
    height: 0.5rem;
    width: 1.875rem;
    border: 1px solid #0000002e;
    border-radius: 1.063;
    background-color: #fff;
    border-radius: 0.5rem;
  }
  .top {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: auto;
    height: 11px;
    .chair-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    .chair-cont {
      transform: rotate(90deg);
      margin: -6px;
    }
    .table-cont {
      background-color: #fff;
      border: 1px solid #0000002e;
      height: 100%;
      width: 100%;
      border-radius: 0.588rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .inner-cont {
        background-color: #eee9fd;
        height: 60%;
        width: 60%;
        border-radius: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          height: 1.5rem;
          width: 1.5rem;
          background-color: #fff;
          border-radius: 50%;
        }
        .img-status {
          height: 1.5rem;
          width: 1.5rem;
          filter: brightness(0) saturate(100%) invert(90%) sepia(31%)
            saturate(0%) hue-rotate(90deg) brightness(113%) contrast(101%);
        }
        .gradient-text {
          font-family: "Inter";
          font-size: 0.75rem;
          font-weight: 600;
          color: #6f47eb;
        }
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: center;
    .chair-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .table-name {
    display: flex;
    justify-content: center;
    background-color: #140936;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-family: "Poppins";
    font-size: 0.75rem;
    font-weight: 600;
    width: max-content;
    margin: auto;
    margin-top: 0.15rem;
  }
  .table-count {
    display: flex;
    justify-content: center;
    background-color: #20c9ac;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-family: "Poppins";
    font-size: 0.75rem;
    font-weight: 600;
    width: max-content;
    margin: auto;
    margin-top: -1.5rem;
    margin-bottom: 0.3rem;
    align-items: center;
    column-gap: 0.25rem;
    img {
      height: 0.563rem;
    }
  }
  .covers-count {
    display: flex;
    justify-content: center;
    background: #7047ebbb;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-family: "Poppins";
    font-size: 0.75rem;
    font-weight: 600;
    width: max-content;
    margin: auto;
    margin-top: -1.5rem;
    margin-bottom: 0.3rem;
    align-items: center;
    column-gap: 0.25rem;
  }
  .drag {
    position: absolute;
    right: -0.4rem;
    margin-top: -0.2rem;
    img {
      height: 0.75rem;
    }
  }
}
