.FloorView {
  width: 100%;
  position: relative;
  padding-bottom: 2%;
  .header-container {
    display: flex;
    // padding-top: 1.375rem;
    width: 100%;
    border-bottom: 1px solid #ededed;
    background-color: #ffffff !important;
    position: fixed;
    top: 4rem;
    z-index: 10;
    height: 3.375rem;
    .arrow-container {
      display: flex;
      border: 1px solid #e4e4e4;
      height: 1.8rem;
      margin-top: 0.8rem;
      border-radius: 2rem;
      margin-left: 1rem;
      margin-right: 1rem;
      .arrow-left {
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #e4e4e4;
        .img-arr {
          height: 0.7rem;
          transform: rotate(180deg);
          cursor: pointer;
        }
        .img-arr-filter {
          height: 0.7rem;
          transform: rotate(180deg);

          filter: brightness(0) saturate(100%) invert(99%) sepia(0%)
            saturate(4579%) hue-rotate(168deg) brightness(119%) contrast(86%);
        }
      }
      .arrow-right {
        width: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .img-arr {
          height: 0.7rem;
          cursor: pointer;
        }
        .img-arr-filter {
          height: 0.7rem;
          filter: brightness(0) saturate(100%) invert(99%) sepia(0%)
            saturate(4579%) hue-rotate(168deg) brightness(119%) contrast(86%);
        }
      }
    }
    .empty-arrow-cont {
      width: 5rem;
      margin-left: 1rem;
    }
    .rooms {
      display: flex;
      width: 68%;
      height: inherit;
      align-items: flex-end;
      overflow-x: hidden;
      .content {
        width: max-content;
        text-align: center;
        font-family: "Inter";
        font-size: 0.875rem;
        font-weight: 600;
        color: #84818a;
        padding-bottom: 0.75rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        cursor: pointer;
        width: 150px;
        min-width: 150px;
        max-width: 150px;
      }
      .content-selected {
        color: #2e2c34;
        border-bottom: 2px #6f47eb solid;
      }
    }
    .table-config {
      display: flex;
      border-left: 1px solid #ebeaed;
      height: inherit;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #19191c;
      padding-left: 2.063rem;
      align-items: center;
      cursor: pointer;
      position: relative;
      column-gap: 0.5rem;
      img {
        height: 0.875rem;
      }
      .dropdown {
        width: 132px;
        border: 1px solid #e4e4e4;

        border-radius: 0.3rem;
        position: absolute;
        background-color: #f9f9f9;
        box-shadow: 0px 2px 6px 0px #00000013;
        z-index: 1;
        border-radius: 0.5rem;
        font-family: "Inter";
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 18px;
        text-align: left;
        color: #19191c;
        cursor: pointer;
        margin-top: 0.2rem;
        top: 3rem;
        .item {
          padding: 0.8rem 1.2rem;
        }
        .item:hover {
          background-color: #eee9fd;
          color: #6f47eb;
        }
      }
    }
  }
  .alert-danger {
    width: 78%;
    margin: auto;
    border: 1px solid #ebadad;
    background-color: #faebeb;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .left-content {
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem !important;
        width: 1rem !important;
        background-color: #cc3333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        img {
          height: 0.5rem;
          width: 0.5rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
  .alert-warning {
    width: 78%;
    margin: auto;
    border: 1px solid #f7e1a1;
    background-color: #fdf8e8;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .left-content {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem;
        width: 1rem;
        background-color: #deaf21;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        .danger {
          height: 0.5rem;
          width: 0.5rem;
        }
        .warning {
          height: 0.85rem;
          width: 0.85rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
  .alert-warning-2 {
    width: 78%;
    margin: auto;
    border: 1px solid #f7e1a1;
    background-color: #fdf8e8;
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .left-content {
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
      width: 10%;
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      .cancel {
        height: 1rem;
        width: 1rem;
        background-color: #deaf21;
        border-radius: 50%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        justify-content: center;
        .danger {
          height: 0.5rem;
          width: 0.5rem;
        }
        .warning {
          height: 0.85rem;
          width: 0.85rem;
        }
      }
    }
    .right-content {
      width: 90%;
      display: flex;
      font-family: "Inter";
      font-size: 0.875rem;
      font-weight: 400;
      color: #1a1a1a;
    }
  }
  .floor {
    height: 540px;
    width: 100px;
    margin: 2%;
    box-shadow: 0px 4px 4px 0px #0000001a;
    border-radius: 0.25rem;
    background-image: radial-gradient(#dddddd 0.063rem, transparent 0);
    background-size: 1.2rem 1.2rem;
    position: relative;
    margin-top: 5rem;
    background-color: #fff;
  }
  .selected-tables {
    background-color: #fff;
    height: max-content;
    width: 76%;
    box-shadow: 0px 16px 30px -6px #19191c29;
    margin: auto;
    margin-top: 1.563rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    padding: 1.5rem;
    .table-det {
      display: flex;
      align-items: center;
      img {
        height: 1.125rem;
        width: 1.125rem;
      }
      .text-cont {
        border-right: 1px solid #e4e4e4;
        padding-right: 2rem;
        margin-left: 1.5rem;
        .header {
          font-family: "Inter";
          font-size: 0.95rem;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
          color: #19191c;
        }
        .sub {
          font-family: "Poppins";
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 16.6px;
          color: #828487;
        }
      }
    }
    .grid-tables {
      display: flex;
      margin-left: 2rem;
      column-gap: 1rem;
      .table-item {
        font-family: "Poppins";
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.125rem;
        text-align: center;
        border: 1px solid #e4e4e4;
        padding: 0.5rem 0.4rem;
        border-radius: 0.25rem;
        position: relative;
        min-width: 3rem;
        .close {
          position: absolute;
          height: 1rem;
          width: 1rem;
          background-color: #ea4f3b;
          border-radius: 50%;
          top: -0.4rem;
          right: -0.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img {
            height: 0.55rem;
            width: 0.5rem;
          }
        }
      }
      .empty {
        font-family: "Poppins";
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.125rem;
        text-align: center;
        color: #84818a;
      }
    }
  }
}
