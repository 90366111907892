.DropdownComponent {
  .label {
    margin-bottom: 0.5rem;
    color: #444444;
    font-family: "Inter";
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.2rem;
  }
  .inner-container {
    position: relative;
    display: inline-block;
    width: calc(100% - 2rem);
    border: 1px solid #e4e4e4;
    padding: 0.6rem 1rem;
    border-radius: 0.3rem;
    background-color: #fff;
    .cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 400;
      img {
        height: 0.75rem;
      }
      .dropdown-button {
        font-family: Inter;
        font-size: 0.875rem;
        font-weight: 400;
        color: #19191c;
      }
    }

    .dropdown-content {
      display: block;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 100%;
      box-shadow: 0px 2px 6px 0px #0000001f;
      z-index: 1;
      margin-left: -1rem;
      border-radius: 0.5rem;
      margin-top: 0.65rem;
      max-height: 120px;
      overflow-y: auto;
      .opt {
        font-family: "Inter";
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #19191c;
        display: block;
        padding: 0.6rem 0.875rem;
        cursor: pointer;
      }

      .opt:hover {
        background-color: #eee9fd;
      }
    }
  }
}
